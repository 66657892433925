import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Button from 'components/CustomButtons/Button.js';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import teamStyle from 'assets/jss/material-kit-pro-react/views/aboutUsSections/teamStyle.js';

import FaceJonathan from 'assets/img/faces/jonathan.png';

const useStyles = makeStyles(teamStyle);

export default function SectionTeam() {
    const classes = useStyles();
    return (
        <div>
            <GridContainer>
                <GridItem
                    md={8}
                    sm={8}
                    className={classNames(
                        classes.mrAuto,
                        classes.mlAuto,
                        classes.textCenter
                    )}
                >
                    <Card profile plain>
                        <CardAvatar profile plain>
                            <a href="https://www.linkedin.com/in/jonathan-chan-6b98405/">
                                <img
                                    src={FaceJonathan}
                                    alt="profile-pic"
                                    className={classes.img}
                                />
                            </a>
                        </CardAvatar>
                        <CardBody plain>
                            <h4 className={classes.cardTitle}>Jonathan Chan</h4>
                            <h6 className={classes.textMuted}>CEO & Founder</h6>
                            <p className={classes.cardDescription}>
                                After graduating from USC, Jonathan worked in
                                several industries, including military defense,
                                bio-tech, advertising analytics, and streaming
                                entertainment at Netflix.
                            </p>
                            <p className={classes.cardDescription}>
                                Taking cues from his early work in bio-tech and
                                learnings from working on large distributed
                                systems at Netlfix, he gained the experience and
                                ideas to build Serendipity.
                            </p>
                        </CardBody>
                        <CardFooter className={classes.justifyContent}>
                            <Button
                                href="https://www.linkedin.com/in/jonathan-chan-6b98405/"
                                justIcon
                                simple
                                color="linkedin"
                            >
                                <i className="fab fa-linkedin" />
                            </Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
