import {
    container,
    title,
    main,
    mainRaised,
    mlAuto,
    mrAuto,
    whiteColor,
    grayColor
} from 'assets/jss/material-kit-pro-react.js';

import buttonStyle from 'assets/jss/material-kit-pro-react/components/buttonStyle.js';

const contactUsStyle = {
    main,
    mainRaised,
    mlAuto,
    mrAuto,
    container: {
        ...container,
        zIndex: 1,
        maxWidth: '970px !important'
    },
    title: {
        ...title,
        '&, & + h4': {
            color: whiteColor
        }
    },
    messageTitle: {
        ...title,
        color: grayColor[1]
    },
    contactContent: {
        paddingBottom: '100px'
        // paddingTop: '40px'
    },
    info: {
        paddingBottom: '10px',
        paddingTop: 0
    },
    textCenter: {
        textAlign: 'center'
    },
    block: {
        color: 'inherit',
        padding: '0.9375rem',
        fontWeight: '500',
        fontSize: '12px',
        textTransform: 'uppercase',
        borderRadius: '3px',
        textDecoration: 'none',
        position: 'relative',
        display: 'block'
    },
    inlineBlock: {
        display: 'inline-block',
        padding: '0px',
        width: 'auto'
    },
    list: {
        marginBottom: '0',
        padding: '0',
        marginTop: '0'
    },
    left: {
        float: 'left!important',
        display: 'block'
    },
    right: {
        padding: '15px 0',
        margin: '0',
        float: 'right'
    },
    icon: {
        width: '18px',
        height: '18px',
        top: '3px',
        position: 'relative'
    },
    submitSpinner: {
        color: whiteColor,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -17,
        marginLeft: -17
    },
    description: {
        color: grayColor[0],
        textAlign: 'center'
    },
    contactSection: {
        padding: '80px 0 0',
        '& $cardDescription': {
            fontStyle: 'italic'
        }
    },
    button: buttonStyle.button,
    buttonSuccess: buttonStyle.success
};

export default contactUsStyle;
