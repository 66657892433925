import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

// import { useAuthListener } from './authHook';
import { AuthContext } from './Auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getSubscriptionStatus } from './user-lib';

import Parallax from 'components/Parallax/Parallax.js';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import classNames from 'classnames';
import protectedRouteStyle from './ProtectedRouteStyle';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(protectedRouteStyle);
const backgroundImage = 'assets/img/bg7.jpg';

const ProtectedRoute = () => {
    // a custom hook to keep track of user's auth status
    // const { loggedIn, checkingStatus } = useAuthListener();
    const classes = useStyles();
    const { isAuthenticated, isLoading, userPreferences } =
        useContext(AuthContext);
    return (
        <>
            {
                // display a spinner while auth status being checked
                isLoading ? (
                    <Parallax
                        image={backgroundImage}
                        filter="dark"
                        id="parallax-main"
                    >
                        <div className={classes.container}>
                            <GridContainer justifyContent="center">
                                <GridItem
                                    className={classNames(
                                        classes.mlAuto,
                                        classes.mrAuto,
                                        classes.textCenter
                                    )}
                                >
                                    <CircularProgress
                                        size={35}
                                        thickness={6}
                                        className={classes.inlineBlock}
                                    />
                                </GridItem>
                            </GridContainer>
                        </div>
                    </Parallax>
                ) : isAuthenticated ? (
                    getSubscriptionStatus(userPreferences)
                ) : (
                    <Redirect to="/login" />
                )
            }
        </>
    );
};

export default ProtectedRoute;
