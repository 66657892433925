import React, { useState, useEffect } from 'react';

//https://gist.github.com/EduVencovsky/f8f6c275f42f7352571c92a59309e31d

import {
    checkIsAuthenticated,
    authLogout,
    updateUserPref
} from '../services/auth';

export const AuthContext = React.createContext({});

export default function Auth({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userPreferences, setUserPreferences] = useState(null);

    const checkAuth = () => {
        setIsLoading(true);
        checkIsAuthenticated()
            .then((user) => {
                setIsAuthenticated(true);
                setUserPreferences(user);
            })
            .catch(() => setIsAuthenticated(false))
            .then(() => setIsLoading(false));
    };

    useEffect(() => {
        checkAuth();
    }, []);

    const logout = () => {
        authLogout();
        setIsAuthenticated(false);
    };

    const updateUserContext = async (key, val) => {
        const updatedUserPreferences = userPreferences;
        const updates = { key, val };
        updatedUserPreferences[key] = val;
        const updateReuslt = await updateUserPref(updates);

        if (updateReuslt) {
            setUserPreferences(updatedUserPreferences);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                isLoading,
                logout,
                userPreferences,
                updateUserContext
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
