import React, { useState, useEffect } from 'react';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import CustomTabs from 'components/CustomTabs/CustomTabs.js';

import {
    FormControl,
    FormControlLabel,
    Switch,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-kit-pro-react/views/settingsPageStyle';

// Nightscout schema components
import {
    NS_UI_SETTINGS_MAP,
    NS_FIELD_TYPES
} from 'lib/nightscout/nightscout-settings-schema';
import {
    setNightscoutSettings,
    restartNightscoutServer,
    getNsSettingsSchema
} from 'lib/nightscout';

const useStyles = makeStyles(styles);

export default function NSConfigSettings(props) {
    const classes = useStyles();

    const { handleContextUpdate, userPreferences } = props;

    const { serendipity, nightscout } = userPreferences;

    const { serverState } = serendipity;

    const [settingsApplied, setSettingsApplied] = useState(false);

    const [nsSettingsSchema, setNsSettingsSchema] = useState({});

    const [nsSettingsTabs, setNsSettingsTabs] = useState([]);

    const handleSettingInput = async (event) => {
        setSettingsApplied(true);

        const { target } = event;

        const updatedNSContext = nightscout;

        const { value, checked } = target;

        if (Object.hasOwn(target, 'checked')) {
            updatedNSContext[target.name] = checked;
        } else {
            updatedNSContext[target.name] = value;
        }

        // Refresh the view with the new settings.
        await buildView();
        handleContextUpdate('nightscout', updatedNSContext);
    };

    /**
     *
     * 0.  Fetch user's saved preferences
     * 1.  Fetch settings schmea
     * 2.  Build out the view with the schema, using the user's preferences.
     *
     */
    /**
     * Todo May 30 - Handle when the settings update fails here.
     */
    const handleSettingsSubmit = async () => {
        setSettingsApplied(false);

        const { httpStatusCode, message } = await setNightscoutSettings();
        if (httpStatusCode !== 200) {
            console.log('An error occurred: ', message);
        }
    };

    const handleRestartSubmit = async () => {
        const { httpStatusCode, message } = await restartNightscoutServer();
        if (httpStatusCode !== 200) {
            console.log('An error occurred: ', message);
        }
    };

    // Build the grid container with our settings schema
    const buildView = async () => {
        let tabs = [];

        //Get the key of each setting group from the UI map.
        const settingsGroups = Object.keys(NS_UI_SETTINGS_MAP);

        if (Object.keys(nsSettingsSchema).length !== 0) {
            // For each setting group,
            // Read the setting map.

            settingsGroups.forEach((settingGroup) => {
                const { settingName, settingIcon, settingMap } =
                    NS_UI_SETTINGS_MAP[settingGroup];
                // Build out the tab content by iterating over the settings maps.
                let tabContent = [];

                // Using the settings defaults,
                // build a component for any setting entry in our settings group

                for (
                    let settingCounter = 0;
                    settingCounter < settingMap.length;
                    settingCounter++
                ) {
                    const settingField = settingMap[settingCounter];

                    // If the value is present in the UI schema, get the default
                    // from our server fetched schema.

                    const nsSettingField = nsSettingsSchema[settingField];

                    // Ingest the shape of the setting entry.

                    const { name, type, enabled, nsOptions } = nsSettingField;

                    // Todo:  Fix this because a false value may not render here correctly.
                    let nsFieldLabel = name;
                    let nsEntryField;

                    let nsValue = nightscout[settingField];

                    if (typeof nsValue === 'undefined') {
                        nsValue = nsSettingsSchema[settingField].nsDefault;
                    }

                    switch (type) {
                        case NS_FIELD_TYPES.SELECT: {
                            nsEntryField = (
                                <div>
                                    <FormControl
                                        fullWidth
                                        className={classes.nsSettingSelect}
                                    >
                                        <InputLabel htmlFor={settingField}>
                                            {nsFieldLabel}
                                        </InputLabel>
                                        <Select
                                            id={settingField}
                                            value={nsValue}
                                            onChange={handleSettingInput}
                                            inputProps={{
                                                id: settingField,
                                                name: settingField
                                            }}
                                        >
                                            {nsOptions.map((nsOption) => {
                                                return (
                                                    <MenuItem
                                                        value={nsOption.value}
                                                        key={
                                                            nsOption.label +
                                                            ' ' +
                                                            settingField
                                                        }
                                                    >
                                                        {nsOption.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            );
                            break;
                        }
                        case NS_FIELD_TYPES.TOGGLE: {
                            let toggleValue;
                            if (
                                nightscout[settingField] === 'true' ||
                                nightscout[settingField] === true
                            ) {
                                toggleValue = true;
                            } else {
                                toggleValue = false;
                            }
                            nsEntryField = (
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                id={settingField}
                                                disabled={!enabled}
                                                name={settingField}
                                                aria-label={name}
                                                onChange={handleSettingInput}
                                                checked={toggleValue}
                                                inputProps={{
                                                    id: settingField,
                                                    name: settingField
                                                }}
                                            />
                                        }
                                        // label={nsFieldLabel}
                                    />
                                </FormControl>
                            );
                            break;
                        }
                        case NS_FIELD_TYPES.TEXT: {
                            nsEntryField = (
                                <CustomInput
                                    formControlProps={{ fullWidth: true }}
                                    // labelText={nsFieldLabel}
                                    id={settingField}
                                    inputProps={{
                                        name: settingField,
                                        type: 'text',
                                        placeholder: nsValue.toString(),
                                        onChange: handleSettingInput
                                    }}
                                />
                            );
                            break;
                        }
                        default: {
                            nsEntryField = <div>Unknown Field!</div>;
                            break;
                        }
                    }
                    tabContent.push(
                        <GridItem xs={12} sm={12} md={6} key={name}>
                            <GridContainer key={name}>
                                <GridItem
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    className={classes.nsSettingsLabel}
                                >
                                    <div>
                                        <span>{name}</span>
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <div className={classes.nsSettingSelect}>
                                        {nsEntryField}
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    );
                }

                tabs.push({
                    tabName: settingName,
                    tabIcon: settingIcon,
                    // tabContent: <form>{tabContent}</form>
                    tabContent: <GridContainer>{tabContent}</GridContainer>
                });
            });
        }

        setNsSettingsTabs(tabs);
    };
    // Load the NS settings schema
    useEffect(() => {
        async function getSchema() {
            const NIGHTSCOUT_SETTINGS_DEFAULTS = await getNsSettingsSchema();
            setNsSettingsSchema(NIGHTSCOUT_SETTINGS_DEFAULTS);
        }

        getSchema();
    }, []);

    // Render the view once the schema has loaded.
    useEffect(() => {
        buildView();
    }, [nsSettingsSchema, userPreferences]);

    return (
        <div>
            <CustomTabs
                title="Nightscout"
                headerColor="success"
                tabs={nsSettingsTabs}
            />
            <Button
                color="danger"
                size="lg"
                onClick={() => {
                    handleSettingsSubmit();
                }}
                disabled={!(settingsApplied && serverState === 'RUNNING')}
                className={classes.saveButton}
            >
                Apply Settings{' '}
            </Button>
            <Button
                color="info"
                size="lg"
                onClick={() => {
                    handleRestartSubmit();
                }}
                disabled={!(serverState === 'RUNNING')}
                className={classes.saveButton}
            >
                Restart Nightscout{' '}
            </Button>
        </div>
    );
}
