export const GTAG_EVENTS = {
    SIGN_UP: 'sign_up', // User starts sign up flow
    LOGIN: 'login', // user logins (already existing user)
    CREATE_ACCOUNT: 'create_account', // New user lands on the Google auth apge
    ACCOUNT_CREATED: 'account_created', // Serendipity & Stripe have created a new user
    STRIPE_BAD_PAYMENT: 'stripe_payment_failed', // Payment payload failed to STripe
    ACCEPTED_TOS: 'accepted_tos', // User accepts TOS
    SIGNUP_TOS_ERROR: 'signup_tos_error', // TOS acceptance error
    FIRST_NS_LOAD: 'first_ns_load', // First dashbaord load after signing up
    NAVIGATE_TO_NS: 'navigate_to_ns', // User navigates to NS
    VIEW_DASHBOARD: 'view_dashboard', // User loads the dashbaord
    GET_STARTED: 'get_started' // User clicks get started
};

export const sendGtagEvent = (eventName, eventOpts) => {
    if (!window.gtag) return;
    window.gtag('event', eventName, eventOpts);
};
