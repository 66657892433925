import {
    container,
    description,
    cardTitle,
    whiteColor
} from 'assets/jss/material-kit-pro-react.js';

const tosStyle = (theme) => ({
    description,
    cardTitle: {
        ...cardTitle,
        color: whiteColor + '  !important'
    },
    container: {
        ...container,
        zIndex: '4',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '100px'
        }
    },

    cardHeader: {
        width: 'auto',
        textAlign: 'center'
    },
    footer: {
        position: 'absolute',
        width: '100%',
        background: 'transparent',
        bottom: '0',
        color: whiteColor,
        zIndex: '2'
    }
});

export default tosStyle;
