import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import { Code, Eco, DataUsage } from '@material-ui/icons';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import testimonialsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js';

const useStyles = makeStyles(testimonialsStyle);

export default function SectionTestimonials({ ...rest }) {
    const classes = useStyles();

    return (
        <div className="cd-section" {...rest}>
            {/* Testimonials 3 START */}
            <div className={classes.testimonials}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={8}
                            md={8}
                            className={
                                classes.mlAuto +
                                ' ' +
                                classes.mrAuto +
                                ' ' +
                                classes.textCenter
                            }
                        >
                            <h2 className={classes.title}>
                                Our mission is to create software that leaves a
                                lasting, positive impact upon society.
                            </h2>
                            <h4 className={classes.description}>
                                Our founding pricipals are focused around three
                                values that provide the focus we need to create
                                the best solutions for our customers.
                            </h4>
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem md={4} sm={4} className={classes.textCenter}>
                            <InfoArea
                                title="Leverage Technology"
                                description="Leverage technology to reduce the toil
                                        of managing diabetes."
                                icon={Code}
                                iconColor="rose"
                                vertical
                            />
                        </GridItem>
                        <GridItem md={4} sm={4} className={classes.textCenter}>
                            <InfoArea
                                title="Utilize Data Science"
                                description="Utilize data science and analytics to
                                        make data-driven decisions with
                                        real-world data."
                                icon={DataUsage}
                                iconColor="info"
                                vertical
                            />
                        </GridItem>
                        <GridItem md={4} sm={4} className={classes.textCenter}>
                            <InfoArea
                                title="Create Sustainable Solutions"
                                description="Create sustainable, technological
                                        solutions that add tremendous value to
                                        our users for generations to come."
                                icon={Eco}
                                iconColor="success"
                                vertical
                            />
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );
}
