module.exports = {
    hero: 'Your Nightscout & Diabetes Data Solution.',
    heroSub:
        'Simple Nightscout setup and maintenance.  Direct access to your device data.',
    featureHero: 'We launch and maintain Nightscout for you.',
    primaryFeature: 'We manage Nightscout for you',
    primaryFeatureSub: 'Nightscout is launched as soon as you sign up!',
    secondaryFeature: 'Cloud based',
    secondaryFeatureSub:
        'Our service is built on top of the best cloud infrastructure.  Access Nightscout wherever you have internet access.',
    tertiaryFeature: 'MongoDB included',
    tertiaryFeatureSub:
        'MongoDB is included.  You get 4GB of storage for free!',
    quadFeature: 'Integration with more devices & services',
    quadFeatureSub: 'More reports and data access, with new features coming!',
    callToActionButtonText: 'Questions?',
    callToActionHeader: "Drop us your email address and we'll get in touch!",
    callToActionSub: 'Contact Us.',
    freeTierPricing: '0.00',
    monthlyTierPricing: '12.99',
    dexcomServicePricing: '2.99'
};
