import firebase from './firebase';
import { getPreferences, setPreferences } from 'lib/user-lib';
import { getStripeApiPaths, getStripeProduct } from '../services/api';
import { sendGtagEvent, GTAG_EVENTS } from 'lib/analytics';
import { PRODUCT_TYPE } from 'lib/PricingHandler';
const stripeApi = getStripeApiPaths();

export const checkIsAuthenticated = () => {
    return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // Look for user preferences.
                // If user preferences don't exist, create a default entry.
                getPreferences(user).then((userPreferences) => {
                    resolve(userPreferences);
                });
            } else {
                reject();
            }
        });
    });
};

export const authLogin = () => {
    // return FirebaseLogin;
};

export const authLogout = () => {
    firebase.auth().signOut();
};

export const updateUserPref = async (updates) => {
    const user = await checkIsAuthenticated();

    return new Promise((resolve, reject) => {
        try {
            setPreferences(user, updates).then((updateResult) => {
                if (updateResult) {
                    resolve(updateResult);
                }
            });
        } catch (e) {
            reject('Unable to update preferences: ' + e);
        }
    });
};

export const getIdToken = async () => {
    const currentUser = await firebase.auth().currentUser;
    const idToken = currentUser ? await currentUser.getIdToken(true) : null;
    return idToken;
};

export const createSerendipityUser = async () => {
    // Use a query parameter to determine which type of subscription the user has selected.
    let planTier = new URLSearchParams(window.location.search).get('plan');

    if (!planTier) {
        planTier = 'unknown';
    }

    const idToken = await getIdToken();
    let customerPayload = {};

    const productIds = getStripeProduct();

    switch (planTier) {
        case PRODUCT_TYPE.DEXCOM_LIVE_MONTHLY:
            customerPayload = {
                product_id: productIds[PRODUCT_TYPE.DEXCOM_LIVE_MONTHLY],
                planType: 'paid'
            };
            break;
        case PRODUCT_TYPE.NIGHTSCOUT_MONTHLY:
            customerPayload = {
                product_id: productIds[PRODUCT_TYPE.NIGHTSCOUT_MONTHLY],
                planType: 'paid'
            };
            break;
        default:
            customerPayload = {
                product_id: productIds[PRODUCT_TYPE.NIGHTSCOUT_MONTHLY],
                planType: 'paid'
            };
            break;
    }

    const result = await fetch(stripeApi.createSerendipityUserPath, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken
        },
        body: JSON.stringify(customerPayload)
    });

    const serendipityReponse = await result.json();

    if (serendipityReponse.status !== 200) {
        sendGtagEvent(GTAG_EVENTS.STRIPE_BAD_PAYMENT);
        return 'pricing';
    } else {
        return serendipityReponse;
    }
};
