/* eslint-disable */
import React, { useContext } from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

// @material-ui/icons
import Fingerprint from '@material-ui/icons/Fingerprint';
import ExitToApp from '@material-ui/icons/ExitToApp';
import PersonAdd from '@material-ui/icons/PersonAdd';

// core components
import Button from 'components/CustomButtons/Button.js';
import styles from 'assets/jss/material-kit-pro-react/components/headerLinksStyle.js';

import { AuthContext } from '../../lib/Auth';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
    const { logout } = useContext(AuthContext);
    const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const smoothScroll = (target) => {
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
    };
    const scrollGo = (element, to, duration) => {
        var start = element.scrollTop,
            change =
                to - start + document.getElementById('main-panel').offsetTop,
            currentTime = 0,
            increment = 20;

        var animateScroll = function () {
            currentTime += increment;
            var val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };
    var onClickSections = {};

    const handleSignout = () => {
        logout();
    };

    const classes = useStyles();
    return (
        <List
            className={classes.list + ' ' + classes.mlAuto}
            id="header-links-panel"
        >
            <ListItem className={classes.listItem}>
                <Button
                    href="/pricing"
                    color="transparent"
                    className={classes.navLink}
                >
                    <PersonAdd className={classes.icons} /> Sign Up
                </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button
                    href="/login"
                    color="transparent"
                    className={classes.navLink}
                    onClick={() => handleSignout()}
                >
                    <Fingerprint className={classes.icons} /> Login
                </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button
                    color="transparent"
                    className={classes.navLink}
                    onClick={() => handleSignout()}
                    href="/"
                >
                    <ExitToApp className={classes.icons} id="signout" /> Sign
                    out
                </Button>
            </ListItem>
        </List>
    );
}

HeaderLinks.defaultProps = {
    hoverColor: 'primary'
};

HeaderLinks.propTypes = {
    dropdownHoverColor: PropTypes.oneOf([
        'dark',
        'primary',
        'info',
        'success',
        'warning',
        'danger',
        'rose'
    ])
};
