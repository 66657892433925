import { getIdToken } from 'services/auth';
import { getAbbottApiPaths } from 'services/api';

export class AbbottClient {
    constructor() {
        this.latestEntry = {
            sgv: 0,
            trend: '',
            timestamp: ''
        };
        this.priorEntry = {
            sgv: 0,
            trend: '',
            timestamp: ''
        };
        this.entries = [];
        this.rawData = {};
        this.TREND_MAP = {
            1: 'DOWN',
            2: 'FORTYFIVEDOWN',
            3: 'FLAT',
            4: 'FORTYFIVEUP',
            5: 'UP'
        };
    }

    formatEntries = async () => {
        try {
            // Pull the graph data from the raw data
            const { graphData } = this.rawData;

            // Always set the latest entry when fetching new data
            this.setLatestEntry();
            this.setPriorEntry();

            let formattedData = [];

            if (graphData.length > 0) {
                formattedData = graphData?.map((entry) => {
                    const { Value, FactoryTimestamp } = entry;
                    //let timestamp = Date.parse(Timestamp);

                    const utcDate = new Date(FactoryTimestamp);
                    utcDate.setTime(
                        utcDate.getTime() -
                            utcDate.getTimezoneOffset() * 60 * 1000
                    );

                    let sgValue = parseInt(Value);

                    return {
                        x: utcDate,
                        y: sgValue,
                        raw: entry
                    };
                });
            }

            this.entries = formattedData;

            return this.entries;
        } catch (err) {
            console.error('Error fetching LibreLink data', err);
            throw new Error('Error fetching LibreLink data');
        }
    };

    /**
     *
     * @param {*} req fetches raw data from the Serendipity proxy server
     * @returns Raw LibreLinkUp data
     */
    getAbbottData = async () => {
        // Get the Serendipity ID token.
        const idToken = await getIdToken();

        const abbottApiPaths = getAbbottApiPaths();
        const response = await fetch(abbottApiPaths.entries, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${idToken}`
            }
        });

        if (response.ok) {
            const data = await response.json();
            //this.rawData = data;
            //return await this.formatEntries();
            const latestEntry = data[data.length - 1];
            const priorEntry = data[data.length - 2];

            this.latestEntry = {
                x: latestEntry.x,
                y: latestEntry.y,
                raw: {
                    Value: latestEntry.y,
                    Trend: this.TREND_MAP[latestEntry.raw.TrendArrow]
                }
            };

            this.priorEntry = {
                x: priorEntry.x,
                y: priorEntry.y,
                raw: {
                    Value: priorEntry.y,
                    Trend: this.TREND_MAP[priorEntry.raw.TrendArrow]
                }
            };
            this.entries = data;
        } else {
            this.rawData = {};
        }
    };

    setLatestEntry = () => {
        const { connection } = this.rawData;
        const { glucoseMeasurement } = connection;

        const { Value, FactoryTimestamp } = glucoseMeasurement;
        //let timestamp = Date.parse(Timestamp);
        let sgValue = parseInt(Value);

        const utcDate = new Date(FactoryTimestamp);
        utcDate.setTime(
            utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000
        );

        this.latestEntry = {
            x: utcDate,
            y: sgValue,
            raw: { Value, Trend: this.TREND_MAP[glucoseMeasurement.TrendArrow] }
        };
    };

    setPriorEntry = () => {
        const { graphData } = this.rawData;

        const { Value, Timestamp, TrendArrow } =
            graphData[graphData.length - 1];
        let timestamp = Date.parse(Timestamp);
        let sgValue = parseInt(Value);

        this.priorEntry = {
            x: timestamp,
            y: sgValue,
            raw: { Value, Trend: this.TREND_MAP[TrendArrow] }
        };
    };

    async updateEntries() {
        return await this.getAbbottData();
    }

    async getEntries() {
        if (this.entries.length === 0) {
            await this.getAbbottData();
            return this.entries;
        }
        return this.entries;
    }
}
