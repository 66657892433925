import React from 'react';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import { FormControl, Select, MenuItem } from '@material-ui/core';

export default function DataSourceSelector(props) {
    const { handleDataSourceSelect, chartDataSource } = props;

    const DATA_SOURCE_OPTIONS = [
        { label: 'Nightscout', value: 'NS' },
        { label: 'Dexcom', value: 'DX' },
        { label: 'Abbott', value: 'AB' }
    ];

    return (
        <GridContainer>
            <GridItem xs={6} sm={6} md={12}>
                <FormControl>
                    <span>Dashboard Data Source</span>
                    <Select
                        id="chart-data-source"
                        name="chart-data-source"
                        value={chartDataSource}
                        onChange={handleDataSourceSelect}
                        inputProps={{
                            name: 'chart-data-source',
                            id: 'chart-data-source'
                        }}
                    >
                        {DATA_SOURCE_OPTIONS.map((dataSourceOption) => {
                            return (
                                <MenuItem
                                    value={dataSourceOption.value}
                                    key={dataSourceOption.label}
                                >
                                    {dataSourceOption.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </GridItem>
        </GridContainer>
    );
}
