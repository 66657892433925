/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useTracking } from 'lib/useTracking';
import 'assets/scss/material-kit-pro-react.scss?v=1.9.0';

// Landing page components
import AboutUsPage from 'views/AboutUsPage/AboutUsPage.js';
import ContactUsPage from 'views/ContactUsPage/ContactUsPage.js';
import LandingPage from 'views/LandingPage/LandingPage.js';
import SignupPage from 'views/SignupPage/SignupPage.js';
import LoginPage from 'views/LoginPage/LoginPage.js';
import PricingPage from 'views/PricingPage/PricingPage.js';
import PaymentSuccessfulPage from 'views/PaymentSuccessful/PaymentSuccessfulPage';
import PaymentFailedPage from 'views/PaymentSuccessful/PaymentFailedPage';
import ErrorPage from 'views/ErrorPage/ErrorPage.js';
import ProtectedRoute from 'lib/ProtectedRoute';
import Auth from 'lib/Auth';

// Dashboard components
import AdminLayout from 'layouts/Admin.js';
import 'assets/scss/material-dashboard-pro-react.scss?v=1.9.0';

export const App = () => {
    useTracking('G-LWKKR4KL9D');
    return (
        <Auth>
            <Switch>
                <Route path="/about-us" component={AboutUsPage} />
                <Route path="/contact-us" component={ContactUsPage} />
                <Route path="/signup" component={SignupPage} />
                <Route path="/login" component={LoginPage} />
                <Route path="/pricing" component={PricingPage} />
                <Route path="/error" component={ErrorPage} />
                <Route
                    path="/payment-success"
                    component={PaymentSuccessfulPage}
                />
                <Route path="/payment-failed" component={PaymentFailedPage} />
                <ProtectedRoute path="/admin" component={AdminLayout} />
                <Route path="/" component={LandingPage} />
            </Switch>
        </Auth>
    );
};

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);

//https://stackoverflow.com/questions/50155909/how-to-use-context-api-with-react-router-v4
//https://gist.github.com/EduVencovsky/f8f6c275f42f7352571c92a59309e31d
