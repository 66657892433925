/*eslint-disable*/
import React, { useState, useEffect, useCallback, useContext } from 'react';

// Auth Context
import { AuthContext } from 'lib/Auth';
import { getIdToken } from 'services/auth';
import { reportingApiPaths } from 'services/api';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-pro-react/views/chartsStyle.js';
const useStyles = makeStyles(styles);

import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import DailyEntriesChart from 'components/Report/daily-entries-chart';

import { DataSourceSelector } from 'components/Serendipity';

import DailyDataTable from 'components/Report/daily-data-table';
import DailyRangeAverages from 'components/Report/daily-range-averages';
import BGSegmentGauge from 'components/Report/bg-segment-guage';

export default function Reports(props) {
    const { userPreferences, updateUserContext } = useContext(AuthContext);
    const { abbott, nightscout, serendipity, uid } = userPreferences;
    const { BG_TARGET_TOP, BG_TARGET_BOTTOM } = nightscout;
    const [reportLoading, setReportLoading] = useState(false);
    const [hourlyRangeTable, setHourlyRangeTable] = useState([]);
    const DEFAULT_DATA = {
        dailyEntries: [],
        dailyAverage: [],
        dailyRangePercentages: {
            inRange: [],
            belowRange: [],
            aboveRange: []
        },
        dailyTimeInRangePerHour: {},
        entries: []
    };

    const [dataTable, setDataTable] = useState([]);

    const [reportData, setReportData] = useState(DEFAULT_DATA);

    const [chartDateRange, setChartDateRange] = useState({
        start: Date.now(),
        end: Date.now()
    });
    const [reportDataSource, setChartDataSource] = useState('NS');

    const handleDataSourceSelect = (event) => {
        const { value } = event.target;
        setChartDataSource(value);
    };

    // Function to convert object to query string
    const constructQueryParams = (params) => {
        return Object.entries(params)
            .map(
                ([key, value]) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            )
            .join('&');
    };

    const getReportData = async () => {
        setReportData(DEFAULT_DATA);
        setReportLoading(true);
        // Get the Serendipity ID token.
        const idToken = await getIdToken();

        const reportParams = {
            reportDataSource,
            chartDateRange
        };

        const queryString = constructQueryParams(reportParams);
        const reportApiPaths = reportingApiPaths();

        const reportUrl = `${reportApiPaths.daily}?${queryString}`;

        const response = await fetch(reportUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${idToken}`
            },
            body: JSON.stringify({
                uid,
                serendipity,
                nightscout,
                abbott
            })
        });

        if (response.ok) {
            const data = await response.json();
            setReportData(data);
        } else {
            setReportData(DEFAULT_DATA);
        }
        setReportLoading(false);
    };

    const generateAbbottEntriesTable = useCallback(() => {
        const entries = reportData.dailyEntries;
        const formattedEntries = entries.map((entry) => {
            const utcDate = new Date(entry.x);

            utcDate.setTime(
                utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000
            );

            return {
                x: utcDate.toLocaleTimeString(),
                y: entry.y,
                originalTime: entry.x
            };
        });
        setDataTable(formattedEntries);
    });

    const getHourlyRangeTable = useCallback(() => {
        const rangeData = reportData.dailyTimeInRangePerHour;

        let tableRows = [];
        Object.keys(rangeData).forEach((key) => {
            const tableRow = (
                <tr key={key}>
                    <td>{key}</td>
                    <td>{rangeData[key].timeInRange}</td>
                    <td>{rangeData[key].timeOutOfRange}</td>
                </tr>
            );

            tableRows.push(tableRow);
        });

        setHourlyRangeTable(tableRows);
    });

    useEffect(() => {
        getHourlyRangeTable();
        generateAbbottEntriesTable();
    }, [reportData]);

    useEffect(() => {
        getReportData();
    }, [reportDataSource]);

    const classes = useStyles();
    return reportLoading ? (
        <div>
            {' '}
            <CircularProgress
                className={classes.inlineBlock}
                size={35}
                thickness={4}
            />
        </div>
    ) : (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <DataSourceSelector
                        handleDataSourceSelect={handleDataSourceSelect}
                        chartDataSource={reportDataSource}
                    />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem>
                            <DailyEntriesChart
                                bgTargetBottom={BG_TARGET_BOTTOM}
                                bgTargetTop={BG_TARGET_TOP}
                                seriesData={reportData.dailyEntries}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <DailyDataTable
                                tableData={reportData.dailyAverage}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <DailyRangeAverages
                                seriesData={reportData.dailyRangePercentages}
                            />
                        </GridItem>
                    </GridContainer>
                    <BGSegmentGauge data={reportData.dailyTimeInRangePerHour} />
                </GridItem>
            </GridContainer>
        </div>
    );
}
