import React, { useState, useEffect } from 'react';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import loginPageStyle from 'assets/jss/material-kit-pro-react/views/tosStyle.js';

import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { sendGtagEvent, GTAG_EVENTS } from 'lib/analytics';
import { makeStyles } from '@material-ui/core/styles';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import termsAndConditions from '../../assets/copy/TermsAndConditions.md';

const useStyles = makeStyles(loginPageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function TermsPage(props) {
    const setTos = props.handleTosSubmit;
    const classes = useStyles();
    const [termsAndConditionsText, setTerms] = useState('');
    const [classicModal, setTosModal] = React.useState(true);

    const handleTosAgree = async (event) => {
        event.preventDefault();
        const tosSubmitResult = await setTos(true);

        if (tosSubmitResult) {
            sendGtagEvent(GTAG_EVENTS.ACCEPTED_TOS);
            setTosModal(false);
            window.location.href = '/payment-success?tos=true';
        } else {
            sendGtagEvent(GTAG_EVENTS.SIGNUP_TOS_ERROR);
            window.location.href = '/error';
        }
    };

    useEffect(() => {
        async function getTerms() {
            const termsBody = await fetch(termsAndConditions);
            const termsText = await termsBody.text();
            setTerms(termsText);
        }
        getTerms();
    }, []);

    return (
        <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Dialog
                        open={classicModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => setTosModal(false)}
                    >
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                        >
                            <h4 className={classes.modalTitle}>
                                Terms and Conditions
                            </h4>
                        </DialogTitle>
                        <DialogContent>
                            <ReactMarkdown>
                                {termsAndConditionsText}
                            </ReactMarkdown>
                        </DialogContent>
                        <DialogActions className={classes.modalFooter}>
                            <Button
                                color="danger"
                                size="lg"
                                onClick={handleTosAgree}
                            >
                                Acknowledge
                            </Button>
                            <Button
                                color="danger"
                                size="lg"
                                href="/"
                                onClick={() => {
                                    window.location.href = '/';
                                }}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </GridItem>
            </GridContainer>
        </div>
    );
}
