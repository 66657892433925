// Nightscout configuration field types

import {
    Dashboard,
    SettingsApplications,
    LaptopMac,
    FeaturedVideo,
    DonutLarge,
    DeviceHub,
    CloudCircle,
    AlarmOutlined,
    Extension,
    InvertColors,
    StayCurrentPortraitOutlined
} from '@material-ui/icons';

// Can be one of text, select, or a binary toggle.
export const NS_FIELD_TYPES = {
    TEXT: 'text',
    SELECT: 'select',
    TOGGLE: 'toggle'
};

export const nsDefaultPlugins = {
    MONGODB_URI: {
        name: 'Mongo DB URI',
        enabled: false,
        type: NS_FIELD_TYPES.TEXT,
        min: 0,
        max: 0,
        nsDefault: '',
        fieldDescription: 'Your Mongo database URI.'
    },
    API_SECRET: {
        name: 'API Secret',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        min: 0,
        max: 0,
        nsDefault: 'MY_API_SECRET',
        fieldDescription:
            'A passphrased used to grant access to devices and your site.  Must be 12 characters long.'
    },
    DISPLAY_UNITS: {
        name: 'Display Units',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        min: 0,
        max: 0,
        nsDefault: 'mg/dl',
        nsOptions: [
            { value: 'mgdl', label: 'mg/dL' },
            { value: 'mmol', label: 'mmol/L' }
        ],
        fieldDescription: 'Blood glucose units for the site.'
    },
    BASE_URL: {
        name: 'Base URL',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        min: 0,
        max: 0,
        nsDefault: '',
        fieldDescription:
            "Used for building links to your site's API.  Usually this is the URL of your Nightscout site."
    },
    AUTH_DEFAULT_ROLES: {
        name: 'Auth Roles',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        min: 0,
        max: 0,
        nsDefault: '',
        nsOptions: [
            { label: 'Readable', value: 'readable' },
            { label: 'Denied', value: 'denied' }
        ],
        fieldDescription:
            'Enable or disable token enabled API-secret based logins.'
    },
    delta: {
        name: 'BG Delta',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: true,
        fieldDescription:
            'Calculates and displays the change between the last 2 BG values. Delta shows a * if time difference is more than 5 minutes.'
    },
    direction: {
        name: 'BG Direction',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: true,
        fieldDescription: 'Displays the trend direction.'
    },
    upbat: {
        name: 'Uploader Battery',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: true,
        fieldDescription:
            'Displays the most recent battery status from the uploader phone.'
    },
    upbat_enable_alerts: {
        name: 'Uploader Battery Alerts',
        enable: true,
        type: NS_FIELD_TYPES.TOGGLE,
        nsDefault: false,
        fieldDescription:
            'Enable or disable uploader battery alerts via Pushover and IFTTT.'
    },
    upbat_warn: {
        name: 'Warn alert battery threshold',
        enable: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: 30,
        fieldDescription: 'WARN threshold for battery alerts.'
    },
    upbat_urgent: {
        name: 'Urgent alert battery threshold',
        enable: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: 20,
        fieldDescription: 'URGENT threshold for battery alerts.'
    },
    timeago: {
        name: 'Time Ago',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: true,
        fieldDescription: 'Displays the time since last CGM entry.'
    },
    TIMEAGO_ENABLE_ALERTS: {
        name: 'Stale Data Alerts',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: true,
        fieldDescription: 'Enable or disable stale data alerts.'
    },
    ALARM_TIMEAGO_WARN: {
        name: 'Stale Data Warning',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: 'on',
        fieldDescription: 'Enable or disable stale data warnings.'
    },
    ALARM_TIMEAGO_WARN_MINS: {
        name: 'Stale Data Warn Threshold',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        min: 0,
        max: 0,
        nsDefault: 15,
        fieldDescription: 'WARN threshold for data alerts.'
    },
    ALARM_TIMEAGO_URGENT: {
        name: 'Stale Data Urgent',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: true,
        fieldDescription: 'Enable or disable stale data urgent alerts.'
    },
    ALARM_TIMEAGO_URGENT_MINS: {
        name: 'Stale Data Urgent Threshold',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        min: 0,
        max: 0,
        nsDefault: 30,
        fieldDescription: 'URGENT threshold for data alerts.'
    },
    devicestatus: {
        name: 'Device Status',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: true,
        fieldDescription: 'Displays device status information.'
    },
    errorcodes: {
        name: 'CGM Error Codes',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: true,
        fieldDescription: 'Generates alarms for CGM codes.'
    },
    ar2: {
        name: 'AR2 Forecasting',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: true,
        fieldDescription: 'Generates alarms based on forecasted values.'
    },
    simplealarms: {
        name: 'Simple BG Alarms',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: true,
        fieldDescription: 'Uses BG thresholds to generate alarms.'
    },
    profile: {
        name: 'Treatment Profile',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: true,
        fieldDescription: 'Allows the user to enter treatment profile settings.'
    },
    dbsize: {
        name: 'Database Size',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: true,
        fieldDescription:
            'Display the size of the Nightscout database as a percentage and in megabytes.'
    }
};

export const nsAdvancedPlugins = {
    rawbg: {
        name: 'Raw BG',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        min: 0,
        max: 0,
        nsDefault: 'unsmoothed',
        nsOptions: [
            { value: 'unsmoothed', label: 'Unsmoothed' },
            { value: 'filtered', label: 'Filtered' },
            { value: 'unfiltered', label: 'Unfiltered' }
        ],
        fieldDescription:
            'Calculates BG using sensor and calibration records and displaying an alternate BG and noise levels.'
    },
    careportal: {
        name: 'Careportal',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: false,
        fieldDescription: 'Enable or disable the treatment logging interface.'
    },
    boluscalc: {
        name: 'Bolus Wizard',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: false,
        fieldDescription: 'Enable or disable the Bolus Wizard interface.'
    },
    food: {
        name: 'Custom Foods',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: false,
        fieldDescription:
            'Enable or disable the Foods editor and food database.'
    },
    iob: {
        name: 'Insulin-on-Board',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: false,
        fieldDescription: 'Enable or disable the Insulin-on-Boaard widget.'
    },
    cob: {
        name: 'Carbs-on-Board',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: false,
        fieldDescription: 'Enable or disable the Carbs-on-Board widget.'
    },
    bwp: {
        name: 'Bolus Wizard Preview',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: false,
        fieldDescription:
            'Enable or disable the automatic snoozing of alarms related to high BG and IOB.'
    },
    cage: {
        name: 'Cannula Age',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: 0,
        fieldDescription:
            'Enable or disable the display of number of hours since the last Site Change treatment was recorded.'
    },
    sage: {
        name: 'Sensor Age',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: 0,
        fieldDescription:
            'Enable or disable the display of number of hours since the last Sensor Start and Sensor Change treatment was recorded.'
    },
    iage: {
        name: 'Insulin Age',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: 0,
        fieldDescription:
            'Enable or disable the display of number of days and hours since hte last Insulin Change treatment was recorded.'
    },
    bage: {
        name: 'Battery Age',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: 0,
        fieldDescription:
            'Enable or disable the display of number of days and hours since hte last Pump Battery Change treatment was recorded.'
    },
    treatmentnotify: {
        name: 'Treatment Notifications',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: 0,
        fieldDescription:
            'Enable or disable notifications when a treatment has been entered and snoozes alarms minutes after a treatment.'
    },
    basal: {
        name: 'Basal Profile',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: 0,
        fieldDescription:
            'Enable or disable the display of the basal rate visualization for the current time and enables or disables the BWP plugin to calculate correction temp basal suggestions.'
    },
    bridge: {
        name: 'Share2Nightscout Bridge',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: 0,
        fieldDescription:
            'Enable or disable glucose readings directly from the Dexcom Share service.'
    },
    override: {
        name: 'Override Mode',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: 0,
        fieldDescription:
            'Enable or disable additional monitoring for DIY automated insulin delivery systems to display real-time visual overrides.'
    },
    XDRIPJS_ENABLE_ALERTS: {
        name: 'XDRIP Enable Alerts',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: false,
        fieldDescription: 'Enable or disable X-Drip battery alerts.'
    },
    XDRIPJS_STATE_NOTIFY_INTRVL: {
        name: 'XDRIP Notify Interval',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        min: 0,
        max: 0,
        nsDefault: 0.5,
        fieldDescription: 'Number of hours between CGM notifications.'
    },
    XDRIPJS_WARN_BAT_V: {
        name: 'XDRIP Battery Warn Voltage',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        min: 0,
        max: 0,
        nsDefault: 300,
        fieldDescription: 'Voltage threshold for low battery warning.'
    },
    alexa: {
        name: 'Amazon Alexa',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: 0,
        fieldDescription: 'Enable or disable Amazon Alexa integration.'
    },
    googlehome: {
        name: 'Google Home / DialogFlow',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: 0,
        fieldDescription: 'Enable to disable Google Home integration.'
    },
    speech: {
        name: 'Speech',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: false,
        fieldDescription: 'Enable or disable speech synthesis plugin.'
    },
    cors: {
        name: 'CORS',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: false,
        fieldDescription: 'Enable or disable CORS access from other sites.'
    },
    // todo enable pushover plugins.
    pushover: {
        name: 'Pushover',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: 0
    },
    // todo enable pushover plugins.
    ifttmaker: {
        name: 'IFTT Maker',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: 0
    }
};

export const nsAlarms = {
    ALARM_URGENT_HIGH: {
        name: 'Browser Alarm Urgent High',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        nsDefault: true,
        fieldDescription: 'Urgent High alarm BG threshold.'
    },
    ALARM_HIGH: {
        name: 'Browser Alarm High',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        nsDefault: true,
        fieldDescription: 'High alarm BG threshold.'
    },
    ALARM_URGENT_LOW: {
        name: 'Browser Alarm Urgent Low',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        nsDefault: true,
        fieldDescription: 'Urgent Low BG threshold.'
    },
    ALARM_LOW: {
        name: 'Browser Alarm Low',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        nsDefault: true,
        fieldDescription: 'Low alarm BG threshold.'
    },
    ALARM_TIMEAGO_WARN: {
        name: 'Browser Alarm Time Ago',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        nsDefault: true,
        fieldDescription:
            'Enable or disable a warning when CGM data has not been received.'
    },
    ALARM_TIMEAGO_WARN_MINS: {
        name: 'Browser Alarm Time Ago Threshold',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        nsDefault: 30,
        fieldDescription:
            'The number of minutes since the last CGM reading before an alert is triggered for TIME AGO WARN.',
        nsOptions: [
            { value: 30, label: '30' },
            { value: 60, label: '60' },
            { value: 90, label: '90' },
            { value: 120, label: '120' }
        ]
    },
    ALARM_TIMEAGO_URGENT: {
        name: 'Browser Alarm Time Ago Urgent',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        nsDefault: true,
        fieldDescription:
            'Enable or disable the an alarm when CGM data has not been received based on the warning threshold.'
    },
    ALARM_TYPES: {
        name: 'Alarm Types',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        nsDefault: 'predict',
        nsOptions: [
            { value: 'simple', label: 'Simple' },
            { value: 'predict', label: 'Predictive' }
        ],
        fieldDescription: 'Choose between simple or predictive alarms.'
    },
    ALARM_URGENT_HIGH_MINS: {
        name: 'Urgent High Alarm Snooze in minutes',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        nsDefault: 30,
        nsOptions: [
            { value: 30, label: '30' },
            { value: 60, label: '60' },
            { value: 90, label: '90' },
            { value: 120, label: '120' }
        ],
        fieldDescription: 'Number of minutes to snooze Urgent High alarms.'
    },
    ALARM_HIGH_MINS: {
        name: 'High Alarm Snooze in minutes',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        nsDefault: 30,
        nsOptions: [
            { value: 30, label: '30' },
            { value: 60, label: '60' },
            { value: 90, label: '90' },
            { value: 120, label: '120' }
        ],
        fieldDescription: 'Number of minutes to snooze High alarms.'
    },
    ALARM_LOW_MINS: {
        name: 'Low Alarm Snooze in minutes',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        nsDefault: 30,
        nsOptions: [
            { value: 30, label: '30' },
            { value: 60, label: '60' },
            { value: 90, label: '90' },
            { value: 120, label: '120' }
        ],
        fieldDescription: 'Number of minutes to snooze Low alarms.'
    },
    ALARM_URGENT_LOW_MINS: {
        name: 'Urgent Low Alarm Snooze in minutes',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        nsDefault: 30,
        nsOptions: [
            { value: 30, label: '30' },
            { value: 60, label: '60' },
            { value: 90, label: '90' },
            { value: 120, label: '120' }
        ],
        fieldDescription: 'Number of minutes to snooze Urgent Low alarms.'
    },
    ALARM_URGENT_MINS: {
        name: 'Urgent Alarm Snooze in minutes',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        nsDefault: 30,
        nsOptions: [
            { value: 30, label: '30' },
            { value: 60, label: '60' },
            { value: 90, label: '90' },
            { value: 120, label: '120' }
        ],
        fieldDescription:
            'Number of minutes to snooze Urgent alarms that not tagged as high or low.'
    },
    ALARM_WARN_MINS: {
        name: 'Warning Alarm Snooze in minutes',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        nsDefault: 30,
        nsOptions: [
            { value: 30, label: '30' },
            { value: 60, label: '60' },
            { value: 90, label: '90' },
            { value: 120, label: '120' }
        ],
        fieldDescription:
            'Number of minutes to snooze Warning alarms that are not tagged as high or low.'
    },
    BG_HIGH: {
        name: 'Urgent High BG Threshold',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: 260,
        fieldDescription: 'Urgent High alarm BG threshold.'
    },
    BG_LOW: {
        name: 'Urgent Low BG Threshold',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: 55,
        fieldDescription: 'Urgent Low alarm BG threshold.'
    },
    BG_TARGET_BOTTOM: {
        name: 'Low BG Threshold',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: 80,
        fieldDescription: 'Low alarm BG threshold.'
    },
    BG_TARGET_TOP: {
        name: 'High BG Threshold',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: 180,
        fieldDescription: 'High alarm BG threshold.'
    }
};

export const nsBrowserSettings = {
    DISPLAY_UNITS: {
        name: 'Display Units',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        nsDefault: 'mg/dl',
        nsOptions: [
            { value: 'mg/dl', label: 'mg/dL' },
            { value: 'mmol/L', label: 'mmol/L' }
        ],
        fieldDescription: 'Blood glucose units for the site.'
    },
    CUSTOM_TITLE: {
        name: 'Nightscout Display Name',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: 'Nightscout',
        fieldDescription: 'The display name for your Nightscout site.'
    },
    TIME_FORMAT: {
        name: '12 or 24 hour time format',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        min: 0,
        max: 0,
        nsDefault: 12,
        nsOptions: [
            { value: 12, label: '12' },
            { value: 24, label: '24' }
        ],
        fieldDescription:
            'Display the time in either 12 hour or 24 hour format.'
    },
    NIGHT_MDOE: {
        name: 'Night Mode',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        nsDefault: false,
        fieldDescription: 'Enable or disable night mode.'
    },
    SHOW_RAWBG: {
        name: 'Display Raw CGM Data',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        nsDefault: 'never',
        nsOptions: [
            { value: 'never', label: 'Never' },
            { value: 'always', label: 'Always' },
            { value: 'noise', label: 'Noise' }
        ],
        fieldDescription: 'Display raw CGM data if available.'
    },
    SHOW_PLUGINS: {
        name: 'Show Plugins',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        nsDefault:
            'delta direction upbat timeago devicestatus errorcodes ar2 simplealarms profile',
        fieldDescription:
            'Enables or disables display of raw CGM data (if available).'
    },
    THEME: {
        name: 'Color theme of CGM Graph',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        nsDefault: 'default',
        nsOptions: [
            { value: 'default', label: 'Default' },
            { value: 'colors', label: 'Colors' },
            { value: 'colorblindfriendly', label: 'Color-Blind Friendly' }
        ],
        fieldDescription:
            'Toggle between default, colored, or color-blind friendly color themes for the CGM graph.'
    },
    LANGUAGE: {
        name: 'Nightscout Display Language',
        enabled: true,
        nsDefault: 'en',
        type: NS_FIELD_TYPES.SELECT,
        nsOptions: [
            { value: 'bg', label: 'Български' },
            { value: 'cs', label: 'Čeština' },
            { value: 'de', label: 'Deutsch' },
            { value: 'dk', label: 'Dansk' },
            { value: 'el', label: 'Ελληνικά' },
            { value: 'en', label: 'English' },
            { value: 'es', label: 'Español' },
            { value: 'fi', label: 'Suomi' },
            { value: 'fr', label: 'Français' },
            { value: 'he', label: 'עברית' },
            { value: 'hr', label: 'Hrvatski' },
            { value: 'it', label: 'Italiano' },
            { value: 'ko', label: '한국어' },
            { value: 'nb', label: 'Norsk (Bokmål)' },
            { value: 'nl', label: 'Nederlands' },
            { value: 'pl', label: 'Polski' },
            { value: 'pt', label: 'Português (Brasil)' },
            { value: 'ro', label: 'Română' },
            { value: 'ru', label: 'Русский' },
            { value: 'sk', label: 'Slovenčina' },
            { value: 'sv', label: 'Svenska' },
            { value: 'tr', label: 'Turkish' },
            { value: 'zh_cn', label: '中文（简体)' },
            { value: 'zh_tw', label: '中文（繁體)' }
        ],
        fieldDescription: 'Change the support language of your Nightscout site.'
    },
    SCALE_Y: {
        name: 'Y axis scaling',
        enabled: true,
        nsDefault: 'log',
        type: NS_FIELD_TYPES.SELECT,
        nsOptions: [
            { value: 'log', label: 'Log' },
            { value: 'linear', label: 'Linear' },
            { value: 'log-dynamic', label: 'Log-dynamic' }
        ],
        fieldDescription:
            'Toggle between logarithmic, linear, or log-dynamic scaling used for the Y-axis for system charts.'
    },
    EDIT_MODE: {
        name: 'Allow editing of treatments',
        enabled: true,
        nsDefault: 'on',
        type: NS_FIELD_TYPES.SELECT,
        nsOptions: [
            { value: 'on', label: 'On' },
            { value: 'off', label: 'Off' }
        ],
        fieldDescription:
            'Enable or disable editing of treatments in the main view.'
    }

    // TO DO: BOLUS RENDER SETTINGS
};

export const nsBridgeSettings = {
    BRIDGE_USER_NAME: {
        name: 'Bridge Username',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: '',
        fieldDescription: 'Username for the Share service'
    },
    BRIDGE_PASSWORD: {
        name: 'Bridge Password',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: '',
        fieldDescription: 'Password for the Share service'
    },
    BRIDGE_INTERVAL: {
        name: 'Bridge Interval',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: '150000',
        fieldDescription:
            'The time (in milliseconds) to wait between each update.'
    },
    BRIDGE_MAX_COUNT: {
        name: 'Record count',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: '1',
        fieldDescription: 'Number of records to attempt to fetch per update.'
    },
    BRIDGE_FIRST_FETCH_COUNT: {
        name: 'First fetch max count',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: '3',
        fieldDescription: 'Number of records to fetch in teh first update.'
    },
    BRIDGE_MAX_FAILURES: {
        name: 'Bridge Max Failures',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: '3',
        fieldDescription: 'Max number of failures before stopping any fetching.'
    },
    BRIDGE_MINUTES: {
        name: 'Bridge Minutes',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: '1400',
        fieldDescription:
            'The time window to search for new data per each update (in minutes).'
    },
    BRIDGE_SERVER: {
        name: 'Bridge Server',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        fieldDescription: 'US OR EU Dexcom server selection.',
        nsDefault: 'US',
        nsOptions: [
            { value: 'US', label: 'United States' },
            { value: 'EU', label: 'Europe' }
        ]
    }
};

export const nsPumpSettings = {
    PUMP_ENABLE_ALERTS: {
        name: 'Enable or disable pump alerts.',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        nsDefault: false,
        fieldDescription: 'Enable or disable all of pump alerts.'
    },
    PUMP_WARN_ON_SUSPEND: {
        name: 'Pump Warn Suspend Alarm',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        nsDefault: false,
        fieldDescription:
            'Enable or disable the alarm when the pump is suspended.'
    },
    // Todo make this a multi-select
    PUMP_FIELDS: {
        name: 'Default Display Fields',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        nsDefault: 'reservoir battery',
        nsOptions: [
            {
                value: 'reservoir',
                label: 'Reservoir'
            },
            {
                value: 'battery',
                label: 'Battery'
            },
            {
                value: 'clock',
                label: 'Clock'
            },
            {
                value: 'status',
                label: 'Status'
            },
            {
                value: 'device',
                label: 'Device'
            }
        ],
        fieldDescription: 'Pump information fields to display.'
    },
    // Todo make this a multi-select
    PUMP_RETRO_FIELDS: {
        name: 'Retro Display Fields',
        enabled: true,
        type: NS_FIELD_TYPES.SELECT,
        nsDefault: 'reservoir battery clock',
        nsOptions: [
            {
                value: 'reservoir',
                label: 'Reservoir'
            },
            {
                value: 'battery',
                label: 'Battery'
            },
            {
                value: 'clock',
                label: 'Clock'
            }
        ],
        fieldDescription: 'Pump information fields to display in retro.'
    },
    PUMP_WARN_CLOCK: {
        name: 'Minutes ago time threshold',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: '30',
        fieldDescription:
            'The number of minutes that need to pass before an alert is triggered.'
    },
    PUMP_URGENT_CLOCK: {
        name: 'Minutes ago for urgent alerts threshold',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: '60',
        fieldDescription:
            'The number of minutes that need to pass before an URGENT alert is triggered.'
    },
    PUMP_WARN_RES: {
        name: 'Pump reservoir warning threshold',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: '5',
        fieldDescription:
            'Threshold for number of units remaining for a warning alert to be triggered.'
    },
    PUMP_URGENT_RES: {
        name: 'Pump reservoir urgent threshold',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: '5',
        fieldDescription:
            'Threshold for number of units remaining for an urgent alert to be triggered.'
    },
    PUMP_WARN_BATT_P: {
        name: 'Pump battery warning alert threshold',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: '20',
        fieldDescription:
            'Threshold of battery percentage before a warning alert is triggered.'
    },
    PUMP_URGENT_BATT_P: {
        name: 'Pump battery urgent alert threshold',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        nsDefault: '20',
        fieldDescription:
            'Threshold of battery percentage before an urgent alert is triggered.'
    },
    PUMP_WARN_BATT_V: {
        name: 'Pump battery voltage warning alert threshold',
        enabled: true,
        nsDefault: '1.35',
        type: NS_FIELD_TYPES.TEXT,
        fieldDescription:
            "Threshold of battery voltage to trigger a warning alert (if percentage isn't available)."
    },
    PUMP_URGENT_BATT_V: {
        name: 'Pump battery voltage urgent alert threshold',
        enabled: true,
        nsDefault: '1.35',
        type: NS_FIELD_TYPES.TEXT,
        fieldDescription:
            "Threshold of battery voltage to trigger an urgent alert (if percentage isn't available)."
    }
};
export const loopSettings = {
    LOOP_ENABLE_ALERTS: {
        name: 'Loop Alerts',
        enabled: true,
        type: NS_FIELD_TYPES.TOGGLE,
        min: 0,
        max: 0,
        nsDefault: false,
        fieldDescription: 'Enable or disable Loop alerts.'
    },
    LOOP_WARN: {
        name: 'Loop Warn Threshold',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        min: 0,
        max: 0,
        nsDefault: 30,
        fieldDescription: 'Threshold for WARN alerts.'
    },
    LOOP_URGENT: {
        name: 'Loop Urgent Threshold',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        min: 0,
        max: 0,
        nsDefault: 60,
        fieldDescription: 'Threshold for URGENT alerts.'
    },
    LOOP_APNS_KEY: {
        name: 'Loop Apple Push Notifications Key',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        min: 0,
        max: 0,
        nsDefault: 60,
        fieldDescription: 'Apple Push Notifications Key'
    },
    LOOP_APNS_KEY_ID: {
        name: 'Loop Apple Push Notifications Key ID',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        min: 0,
        max: 0,
        nsDefault: 60,
        fieldDescription: 'Apple Push Notifications Key ID'
    },
    LOOP_DEVELOPER_TEAM_ID: {
        name: 'Apple Developer Team ID',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        min: 0,
        max: 0,
        nsDefault: 60,
        fieldDescription: 'Apple Developer Team ID'
    },
    LOOP_PUSH_SERVER_ENVIRONMENT: {
        name: 'Apple Push Server Environment',
        enabled: true,
        type: NS_FIELD_TYPES.TEXT,
        min: 0,
        max: 0,
        nsDefault: 60,
        fieldDescription: 'Apple Push Server Environment'
    }
};
export const nsOpenApsSettings = {
    OPENAPS_ENABLE_ALERTS: {
        name: 'Enable OpenAPS Alerts',
        enabled: true,
        nsDefault: false,
        type: NS_FIELD_TYPES.TOGGLE,
        fieldDescription:
            "Enable or disable notifications when OpenAPS isn't looping."
    },
    OPENAPS_WARN: {
        name: 'Open APS last loop threshold',
        enabled: true,
        nsDefault: '30',
        type: NS_FIELD_TYPES.TEXT,
        fieldDescription:
            'Number of minutes since the last loop that needs to be exceeded before a WARN alert is triggered.'
    },
    OPENAPS_URGENT: {
        name: 'Open APS last loop URGENT alert threshold',
        enabled: true,
        nsDefault: '60',
        type: NS_FIELD_TYPES.TEXT,
        fieldDescription:
            'Number of minutes since the last loop that needs to be exceeded before an URGENT alert is triggered.'
    },
    // TODO: Make multi-select
    OPENAPS_FIELDS: {
        name: 'Open APS Display Fields',
        enabled: true,
        nsDefault: 'status-symbol status-label iob meal-assist rssi',
        type: NS_FIELD_TYPES.SELECT,
        nsOptions: [
            {
                value: 'status-symbol',
                label: 'Status Symbol'
            },
            {
                value: 'status-label',
                label: 'Status Label'
            },
            {
                value: 'iob',
                label: 'Insulin on Board'
            },
            {
                value: 'meal-assist',
                label: 'Meal Assist'
            },
            {
                value: 'freq',
                label: 'Frequency'
            },
            {
                value: 'rssi',
                label: 'RSSI'
            }
        ],
        fieldDescription: 'Open APS display fields.'
    },
    OPENAPS_RETRO_FIELDS: {
        name: 'Open APS Retro Display Fields',
        enabled: true,
        nsDefault: 'status-symbol status-label iob meal-assist rssi',
        type: NS_FIELD_TYPES.SELECT,
        nsOptions: [
            {
                value: 'status-symbol',
                label: 'Status Symbol'
            },
            {
                value: 'status-label',
                label: 'Status Label'
            },
            {
                value: 'iob',
                label: 'Insulin on Board'
            },
            {
                value: 'meal-assist',
                label: 'Meal Assist'
            },
            {
                value: 'freq',
                label: 'Frequency'
            },
            {
                value: 'rssi',
                label: 'RSSI'
            }
        ],
        fieldDescription: 'Open APS Retro display fields.'
    },
    OPENAPS_PRED_IOB_COLOR: {
        name: 'IOB Prediction Line Color',
        enabled: true,
        nsDefault: '#1e88e5',
        type: NS_FIELD_TYPES.TEXT,
        fieldDescription:
            'The color to use for IOB prediction lines.  Must be in #RRGGBB format.'
    },
    OPENAPS_PRED_COB_COLOR: {
        name: 'COB Prediction Line Color',
        enabled: true,
        nsDefault: '#FB8C00',
        type: NS_FIELD_TYPES.TEXT,
        fieldDescription:
            'The color to use for COB prediction lines.  Must be in #RRGGBB format.'
    },
    OPENAPS_PRED_ACOB_COLOR: {
        name: 'ACOB Prediction Line Color',
        enabled: true,
        nsDefault: '#FB8C00',
        type: NS_FIELD_TYPES.TEXT,
        fieldDescription:
            'The color to use for ACOB prediction lines.  Must be in #RRGGBB format.'
    },
    OPENAPS_PRED_ZT_COLOR: {
        name: 'ZT Prediction Line Color',
        enabled: true,
        nsDefault: '#00d2d2',
        type: NS_FIELD_TYPES.TEXT,
        fieldDescription:
            'The color to use for ZT prediction lines.  Must be in #RRGGBB format.'
    },
    OPENAPS_PRED_UAM_COLOR: {
        name: 'UAM Prediction Line Color',
        enabled: true,
        nsDefault: '#c9bd60',
        type: NS_FIELD_TYPES.TEXT,
        fieldDescription:
            'The color to use for UAM prediction lines.  Must be in #RRGGBB format.'
    },
    OPENAPS_COLOR_PREDICTION_LINES: {
        name: 'Enable Colored Prediction Line Colors',
        enabled: true,
        nsDefault: true,
        type: NS_FIELD_TYPES.TOGGLE,
        fieldDescription:
            'Enable or disable colored prediction lines vs standard purple color.'
    }
};

/**
 * This map is used for establish the UI schema
 */
export const UI_SETTINGS_SCHEMA = {
    NIGHTSCOUT_SCHEMA: {
        // These are the same as default plugins.
        DEFAULT_PLUGINS: [
            'base_url',
            'delta',
            'direction',
            'upbat',
            'timeago',
            'devicestatus',
            'errorcodes',
            'ar2',
            'simplealarms',
            'profile'
        ],
        ADVANCED_PLUGINS: [
            'careportal',
            'boluscalc',
            'food',
            'rawbg',
            'iob',
            'cob',
            'bwp',
            'cage',
            'sage',
            'iage',
            'bage',
            'treatmentnotify',
            'basal',
            'bolus',
            'bridge',
            'mmconnect',
            'pump',
            'openaps',
            'loop',
            'override',
            'xdripjs',
            'alexa',
            'googlehome',
            'speech',
            'dbsize'
        ],
        BROWSER: [
            'DISPLAY_UNITS',
            'TIME_FORMAT',
            'DAY_START',
            'DAY_END',
            'NIGHT_MODE',
            'SHOW_RAWBG',
            'CUSTOM_TITLE',
            'THEME',
            'ALARM_TIMEAGO_WARN',
            'ALARM_TIMEAGO_WARN_MINS',
            'ALARM_TIMEAGO_URGENT',
            'ALARM_TIMEAGO_URGENT_MINS',
            'SHOW_PLUGINS',
            'SHOW_FORECAST',
            'LANGUAGE',
            'SCALE_Y',
            'EDIT_MODE'
        ],
        ALARMS: [
            'ALARM_TYPES',
            'ALARM_URGENT_HIGH',
            'ALARM_URGENT_HIGH_MINS',
            'ALARM_HIGH',
            'ALARM_HIGH_MINS',
            'ALARM_LOW',
            'ALARM_LOW_MINS',
            'ALARM_URGENT_LOW',
            'ALARM_URGENT_LOW_MINS',
            'ALARM_URGENT_MINS',
            'ALARM_WARN_MINS',
            'ALARM_TIMEAGO_WARN',
            'ALARM_TIMEAGO_WARN_MINS',
            'ALARM_TIMEAGO_URGENT',
            'ALARM_TIMEAGO_URGENT_MINS'
        ],
        PUMP_SETTINGS: [
            'PUMP_ENABLE_ALERTS',
            'PUMP_WARN_ON_SUSPEND',
            'PUMP_FIELDS',
            'PUMP_RETRO_FIELDS',
            'PUMP_WARN_CLOCK',
            'PUMP_URGENT_CLOCK',
            'PUMP_WARN_RES',
            'PUMP_URGENT_RES',
            'PUMP_WARN_BATT_P',
            'PUMP_URGENT_BATT_P',
            'PUMP_WARN_BATT_V',
            'PUMP_URGENT_BATT_V',
            'PUMP_WARN_BATT_QUIET_NIGHT'
        ],
        OPENAPS_SETTINGS: [
            'OPENAPS_ENABLE_ALERTS',
            'OPENAPS_WARN',
            'OPENAPS_URGENT',
            'OPENAPS_FIELDS',
            'OPENAPS_RETRO_FIELDS',
            'OPENAPS_PRED_IOB_COLOR',
            'OPENAPS_PRED_COB_COLOR',
            'OPENAPS_PRED_ACOB_COLOR',
            'OPENAPS_PRED_ZT_COLOR',
            'OPENAPS_PRED_UAM_COLOR',
            'OPENAPS_COLOR_PREDICTION_LINES'
        ],

        MMCONNECT_SETTINGS: [
            'MMCONNECT_USER_NAME',
            'MMCONNECT_PASSWORD',
            'MMCONNECT_INTERVAL',
            'MMCONNECT_MAX_RETRY_DURATION',
            'MMCONNECT_SGV_LIMIT',
            'MMCONNECT_VERBOSE',
            'MMCONNECT_STORE_RAW_DATA',
            'MMCONNECT_SERVER'
        ],

        BRIDGE_SETTINGS: [
            'BRIDGE_USER_NAME',
            'BRIDGE_PASSWORD',
            'BRIDGE_INTERVAL',
            'BRIDGE_MAX_COUNT',
            'BRIDGE_FIRST_FETCH_COUNT',
            'BRIDGE_MAX_FAILURES',
            'BRIDGE_MINUTES',
            'BRIDGE_SERVER'
        ],
        LOOP_SETTINGS: [
            'LOOP_ENABLE_ALERTS',
            'LOOP_WARN',
            'LOOP_URGENT',
            'LOOP_APNS_KEY',
            'LOOP_APNS_KEY_ID',
            'LOOP_DEVELOPER_TEAM_ID',
            'LOOP_PUSH_SERVER_ENVIRONMENT'
        ],
        XDRIP_SETTINGS: [
            'XDRIPJS_ENABLE_ALERTS',
            'XDRIPJS_STATE_NOTIFY_INTRVL',
            'XDRIPJS_WARN_BAT_V'
        ],
        EXTENDED_SETTINGS: [
            'DEVICESTATUS_ADVANCED',
            'UPBAT_ENABLE_ALERTS',
            'UPBAT_WARN',
            'UPBAT_URGENT',
            'TIMEAGO_ENABLE_ALERTS',
            'ERRORCODES_INFO',
            'ERRORCODES_WARN',
            'ERRORCODES_URGENT',
            'AR2_CONE_FACTOR',
            'BG_HIGH',
            'BG_TARGET_TOP',
            'BG_TARGET_BOTTOM',
            'BG_LOW',
            'PROFILE_HISTORY',
            'PROFILE_MULTIPLE',
            'DISPLAY',
            'BWP_WARN',
            'BWP_URGENT',
            'BWP_SNOOZE_MINS',
            'BWP_SNOOZE',
            'CAGE_ENABLE_ALERTS',
            'CAGE_INFO',
            'CAGE_WARN',
            'CAGE_URGENT',
            'CAGE_DISPLAY',
            'SAGE_ENABLE_ALERTS',
            'SAGE_INFO',
            'SAGE_WARN',
            'SAGE_URGENT',
            'IAGE_ENABLE_ALERTS',
            'IAGE_INFO',
            'IAGE_WARN',
            'IAGE_URGENT',
            'BAGE_ENABLE_ALERTS',
            'BAGE_DISPLAY',
            'BAGE_INFO',
            'BAGE_WARN',
            'BAGE_URGENT',
            'TREATMENTNOTIFY_SNOOZE_MINS',
            'TREATMENTNOTIFY_INCLUDE_BOLUSES_OVER',
            'BASAL_RENDER',
            'BOLUS_RENDER_OVER',
            'BOLUS_RENDER_FORMAT',
            'BOLUS_RENDER_FORMAT_SMALL',
            'DBSIZE_IN_MIB'
        ]
    }
};

export const NS_UI_SETTINGS_MAP = {
    DEFAULT_PLUGINS: {
        settingName: 'Default Plugins',
        settingIcon: Dashboard,
        settingMap: UI_SETTINGS_SCHEMA.NIGHTSCOUT_SCHEMA.DEFAULT_PLUGINS
    },
    ADVANCED_PLUGINS: {
        settingName: 'Advanced Plugins',
        settingIcon: SettingsApplications,
        settingMap: UI_SETTINGS_SCHEMA.NIGHTSCOUT_SCHEMA.ADVANCED_PLUGINS
    },
    ALARM_SETTINGS: {
        settingName: 'Alarms',
        settingIcon: AlarmOutlined,
        settingMap: UI_SETTINGS_SCHEMA.NIGHTSCOUT_SCHEMA.ALARMS
    },
    EXTENDED_SETTINGS: {
        settingName: 'Extended Settings',
        settingIcon: Extension,
        settingMap: UI_SETTINGS_SCHEMA.NIGHTSCOUT_SCHEMA.EXTENDED_SETTINGS
    },
    BROWSER_SETTINGS: {
        settingName: 'Browser',
        settingIcon: LaptopMac,
        settingMap: UI_SETTINGS_SCHEMA.NIGHTSCOUT_SCHEMA.BROWSER
    },
    PUMP_SETTINGS: {
        settingName: 'Pump',
        settingIcon: FeaturedVideo,
        settingMap: UI_SETTINGS_SCHEMA.NIGHTSCOUT_SCHEMA.PUMP_SETTINGS
    },
    OPENAPS_SETTINGS: {
        settingName: 'Open APS',
        settingIcon: DonutLarge,
        settingMap: UI_SETTINGS_SCHEMA.NIGHTSCOUT_SCHEMA.OPENAPS_SETTINGS
    },
    LOOP_SETTINGS: {
        settingName: 'Loop',
        settingIcon: CloudCircle,
        settingMap: UI_SETTINGS_SCHEMA.NIGHTSCOUT_SCHEMA.LOOP_SETTINGS
    },
    BRIDGE_SETTINGS: {
        settingName: 'Bridge',
        settingIcon: DeviceHub,
        settingMap: UI_SETTINGS_SCHEMA.NIGHTSCOUT_SCHEMA.BRIDGE_SETTINGS
    },
    MMCONNECT_SETTINGS: {
        settingName: 'MMConnect',
        settingIcon: StayCurrentPortraitOutlined,
        settingMap: UI_SETTINGS_SCHEMA.NIGHTSCOUT_SCHEMA.MMCONNECT_SETTINGS
    },
    XDRIP_SETTINGS: {
        settingName: 'xDrip-JS',
        settingIcon: InvertColors,
        settingMap: UI_SETTINGS_SCHEMA.NIGHTSCOUT_SCHEMA.XDRIP_SETTINGS
    }
};
