import React, { useState, useEffect } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import styles from 'assets/jss/serendipity/server-status-header.js';

const SERVER_STATE = {
    STARTING: { label: 'Starting...', class: 'requested' },
    RUNNING: { label: 'Online', class: 'running' },
    NOT_RUNNING: { label: 'Offline', class: 'stopped' },
    STOPPED: { label: 'Stopped', class: 'stopped' },
    REQUESTED: { label: 'Waiting for resources...', class: 'requested' },
    STOPPING: { label: 'Stopping...', class: 'requested' }
};

const useStyles = makeStyles(styles);

export default function ServerStatusHeader(props) {
    const classes = useStyles();

    const [serverState, setServerState] = useState('STOPPED');
    const [baseUrl, setBaseUrl] = useState('');

    useEffect(() => {
        const { userPreferences } = props;
        const { serendipity, nightscout } = userPreferences;
        const { serverState } = serendipity;
        const { base_url } = nightscout;
        setServerState(serverState);
        setBaseUrl(base_url);
    }, [props]);

    return (
        <GridContainer>
            <GridItem xs={6} sm={6} md={12}>
                <div className={classes.statusLabelContainer}>
                    <span>Nightscout Server Status</span>
                </div>
                <div className={classes.statusContainer}>
                    {serverState === 'STARTING' ||
                    serverState === 'REQUESTED' ||
                    serverState === 'STOPPING' ? (
                        <CircularProgress
                            color={'primary'}
                            size={20}
                            thickness={5}
                            style={{ marginRight: '5px' }}
                        />
                    ) : (
                        <span
                            className={classes[SERVER_STATE[serverState].class]}
                        />
                    )}
                    <span className={classes.statusLabel}>
                        {SERVER_STATE[serverState].label}
                    </span>
                    <span className={classes.serverLink}>
                        <Link href={baseUrl}>
                            <LinkIcon fontSize="small" />
                        </Link>
                    </span>
                </div>
            </GridItem>
        </GridContainer>
    );
}
