import {
    title,
    mrAuto,
    mlAuto,
    grayColor,
    whiteColor
} from 'assets/jss/material-kit-pro-react.js';

import buttonStyle from 'assets/jss/material-kit-pro-react/components/buttonStyle.js';

const workStyle = {
    mrAuto,
    mlAuto,
    section: {
        padding: '70px 0'
    },
    title: {
        ...title,
        marginBottom: '1rem',
        marginTop: '30px',
        minHeight: '32px',
        textDecoration: 'none',
        textAlign: 'center'
    },
    description: {
        color: grayColor[0],
        textAlign: 'center'
    },
    textCenter: {
        textAlign: 'center'
    },
    textArea: {
        marginRight: '15px',
        marginLeft: '15px'
    },
    botForm: {
        position: 'absolute',
        left: '-5000px'
    },
    submitSpinner: {
        color: whiteColor,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -17,
        marginLeft: -17
    },
    ...buttonStyle
};

export default workStyle;
