import { getIdToken } from 'services/auth';

import { getNsApiPaths } from 'services/api';

const nsApi = getNsApiPaths();

const queryApi = async function (opts, path) {
    const parameters = new URLSearchParams(opts.parameters).toString();
    const idToken = await getIdToken();

    // To-do:  Fix this when there is no NS API URL.
    if (opts.nsApiUrl) {
        const response = await fetch(path + '?' + parameters, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-NIGHTSCOUT-HOST': opts.nsApiUrl,
                Authorization: 'Bearer ' + idToken
            },
            signal: AbortSignal.timeout(5000)
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            return [];
        }
    } else {
        return [];
    }
};

const getNightscoutEntries = async function (opts) {
    const { nsApiUrl, queryStartTime, queryEndTime, count } = opts;

    const queryEnd = queryEndTime ? '&find[date][$lte]=' + queryEndTime : '';

    const entriesQuery =
        'find[date][$gte]=' + queryStartTime + queryEnd + '&count=' + count;

    const query = {
        nsApiUrl,
        parameters: {
            nsQuery: entriesQuery
        }
    };

    try {
        const entries = await queryApi(query, nsApi.entries);
        return entries;
    } catch (e) {
        return [];
    }
};

const getTreatments = async function (opts) {
    try {
        const treatments = await queryApi(opts, nsApi.treatments);
        return treatments;
    } catch (e) {
        return [];
    }
};

const getDeviceStatus = async function (opts) {
    try {
        const deviceStatus = await queryApi(opts, nsApi.deviceStatus);
        return deviceStatus;
    } catch (e) {
        return [];
    }
};

const restartNightscoutServer = async function () {
    const idToken = await getIdToken();
    const nightscoutServerResponse = await fetch(nsApi.restartNightscout, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + idToken
        }
    });

    return nightscoutServerResponse;
};

const setNightscoutSettings = async function () {
    const idToken = await getIdToken();

    const nightscoutServerResponse = await fetch(nsApi.updateSettings, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + idToken
        }
    });

    if (nightscoutServerResponse.ok) {
        const settingsPayload = await nightscoutServerResponse.json();
        return settingsPayload;
    } else {
        return [];
    }
};

const getNsSettingsSchema = async function () {
    const idToken = await getIdToken();
    const schemaResponse = await fetch(nsApi.schema, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + idToken
        }
    });

    if (schemaResponse.ok) {
        const scehma = await schemaResponse.json();
        return scehma;
    } else {
        return {};
    }
};

export {
    getNightscoutEntries,
    getTreatments,
    getDeviceStatus,
    restartNightscoutServer,
    setNightscoutSettings,
    getNsSettingsSchema
};
