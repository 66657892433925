import {
    grayColor,
    cardTitle,
    successColor,
    dangerColor
} from 'assets/jss/material-dashboard-pro-react.js';

import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js';
import buttonGroupStyle from 'assets/jss/material-dashboard-pro-react/buttonGroupStyle.js';

const settingsStyle = {
    cardTitle: {
        marginTop: '0',
        marginBottom: '3px',
        color: grayColor[2],
        fontSize: '18px'
    },
    cardHeader: {
        zIndex: '3'
    },
    cardContentLeft: {
        padding: '15px 20px 15px 10px',
        position: 'relative'
    },
    cardContentRight: {
        padding: '15px 20px 15px 15px',
        position: 'relative'
    },
    cardContentBottom: {
        padding: '15px 0px 0px 0px',
        position: 'relative'
    },
    cardCategory: {
        color: grayColor[0],
        fontSize: '18px',
        paddingTop: '25px',
        marginBottom: '0',
        marginTop: '0',
        margin: '0'
    },
    marginRight: {
        marginRight: '25px'
    },
    icons: {
        width: '17px',
        height: '17px'
    },
    ...buttonGroupStyle,
    socialButtonsIcons: {
        fontSize: '18px',
        marginTop: '-2px',
        position: 'relative'
    },
    ...customCheckboxRadioSwitch,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    },
    staticFormGroup: {
        marginLeft: '0',
        marginRight: '0',
        paddingBottom: '10px',
        margin: '8px 0 0 0',
        position: 'relative',
        '&:before,&:after': {
            display: 'table',
            content: '" "'
        },
        '&:after': {
            clear: 'both'
        }
    },
    staticFormControl: {
        marginBottom: '0',
        paddingTop: '8px',
        paddingBottom: '8px',
        minHeight: '34px'
    },
    inputAdornment: {
        marginRight: '8px',
        position: 'relative'
    },
    inputAdornmentIconSuccess: {
        color: successColor[0] + '!important'
    },
    inputAdornmentIconError: {
        color: dangerColor[0] + '!important'
    },
    nsSettingsLabel: {
        justifyContent: 'flex-start',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '50px'
    },
    nsSettingSelect: {
        padding: '7px',
        margin: '7px '
    },
    saveButton: {
        float: 'right'
    }
};

export default settingsStyle;
