/* eslint-disable */
import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import styles from 'assets/jss/material-kit-pro-react/components/serendipityFooterStyle.js';

const useStyles = makeStyles(styles);

export default function Footer(props) {
    const { children, theme, big, className, darkFont } = props;
    const classes = useStyles();
    const themeType =
        theme === 'transparent' || theme == undefined ? false : true;

    const supportUrl =
        process.env.SERENDIPITY_ENV == 'local'
            ? 'https://support.test.serendipitybio.com'
            : 'https://support.serendipitybio.com';
    const footerClasses = classNames({
        [classes.footer]: true,
        [classes[theme]]: themeType,
        [classes.big]: big || children !== undefined,
        [className]: className !== undefined
    });

    return (
        <footer className={footerClasses}>
            <div className={classes.container}>
                {children !== undefined ? (
                    <div>
                        <div className={classes.content}>{children}</div>
                        <hr />
                    </div>
                ) : (
                    ' '
                )}
                <div className={darkFont ? classes.darkFont : undefined}>
                    <div className={classes.left}>
                        <List className={classes.list}>
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/"
                                    className={classes.block}
                                    target="_self"
                                >
                                    Serendipity Bio
                                </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/about-us"
                                    className={classes.block}
                                    target="_self"
                                >
                                    About us
                                </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/signup"
                                    target="_self"
                                    className={classes.block}
                                >
                                    Sign Up
                                </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href={supportUrl}
                                    target="_self"
                                    className={classes.block}
                                >
                                    Support
                                </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                                <a
                                    href="/contact-us"
                                    target="_self"
                                    className={classes.block}
                                >
                                    Contact
                                </a>
                            </ListItem>
                        </List>
                    </div>
                    <div className={classes.right}>
                        <div className={classes.right}>
                            &copy; {1900 + new Date().getYear()} by{' '}
                            <a href="/" target="_self">
                                Serendipity Bio
                            </a>
                            <span>
                                {' '}
                                v.{process.env.REACT_APP_SERENDIPITY_VERSION}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={classes.clearFix} />
            </div>
        </footer>
    );
}

Footer.propTypes = {
    theme: PropTypes.oneOf(['dark', 'white', 'transparent']),
    big: PropTypes.bool
};
