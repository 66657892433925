import {
    container,
    mrAuto,
    mlAuto
} from 'assets/jss/material-kit-pro-react.js';

const protectedRouteStyle = (theme) => ({
    container: {
        ...container,
        zIndex: '4',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '100px'
        }
    },
    block: {
        color: 'inherit',
        padding: '0.9375rem',
        fontWeight: '500',
        fontSize: '12px',
        textTransform: 'uppercase',
        borderRadius: '3px',
        textDecoration: 'none',
        position: 'relative',
        display: 'block'
    },
    textCenter: {
        textAlign: 'center'
    },
    inlineBlock: {
        display: 'inline-block',
        padding: '0px',
        width: 'auto'
    },

    mrAuto,
    mlAuto
});

export default protectedRouteStyle;
