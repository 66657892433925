import React, { useState, useEffect } from 'react';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button.js';
import loginPageStyle from 'assets/jss/material-kit-pro-react/views/tosStyle.js';

import { sendGtagEvent, GTAG_EVENTS } from 'lib/analytics';
import { makeStyles } from '@material-ui/core/styles';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import welcomeTerms from '../../assets/copy/Welcome.md';

const useStyles = makeStyles(loginPageStyle);

export default function WelcomeSection() {
    const classes = useStyles();
    const [welcomeText, setWelcome] = useState('');

    useEffect(() => {
        async function getWelcome() {
            const welcomeBody = await fetch(welcomeTerms);
            const welcome = await welcomeBody.text();
            setWelcome(welcome);
        }
        getWelcome();
    }, []);

    const handleWelcomeClick = async (event) => {
        event.preventDefault();
        sendGtagEvent(GTAG_EVENTS.GET_STARTED);
        window.location.href = '/admin';
    };

    return (
        <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader
                            color="danger"
                            signup
                            className={classes.cardHeader}
                        >
                            <h4 className={classes.cardTitle}>
                                Welcome to Serendipity!
                            </h4>
                        </CardHeader>

                        <CardBody>
                            <ReactMarkdown>{welcomeText}</ReactMarkdown>
                        </CardBody>
                        <CardFooter>
                            <Button
                                color="danger"
                                size="lg"
                                onClick={handleWelcomeClick}
                            >
                                Get Started
                            </Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
