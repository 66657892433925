import React from 'react';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import TimeGauge from './time-guage';

import { makeStyles } from '@material-ui/core/styles';
import { timeGaugeStyles } from '../../assets/jss/DailyReportStyle';

const useStyles = makeStyles(timeGaugeStyles);

export default function BGSegmentGauge(props) {
    const classes = useStyles();
    const { data } = props;

    // Return a filled segment based on the time in range from the data prop.
    let timeGauges = [];

    // Given a time range, calculate the segments to fill in the gauge.
    Object.keys(data)
        .slice(0, 10)
        .forEach((key) => {
            const timeRange = data[key];
            const timeInRange = timeRange.timeInRange.split(' ')[0];
            const timeOutOfRange = timeRange.timeOutOfRange.split(' ')[0];

            // Get the percentage of time in range.
            const inRangePercentage = Math.round((timeInRange / 60) * 100);

            const outOfRangePercentage = Math.round(
                (timeOutOfRange / 60) * 100
            );

            timeGauges.push(
                <tr key={key}>
                    <td>
                        <span>{key}</span>
                    </td>

                    <td className={classes.gaugeEntry}>
                        <TimeGauge
                            inRangePercentage={inRangePercentage}
                            outOfRangePercentage={outOfRangePercentage}
                        />
                    </td>
                    <td>
                        <span>{timeInRange} </span>
                    </td>
                    <td>
                        <span>{timeOutOfRange} </span>
                    </td>
                </tr>
            );
        });

    return (
        <Card>
            <CardHeader className={classes.cardHeader}>
                <div className={classes.cardTitle}>
                    <span className={classes.cardTitleText}>
                        Hourly Time in Range
                    </span>
                </div>
            </CardHeader>

            <CardBody className={classes.cardBody}>
                <div className={classes.dailyMinMaxTableContainer}>
                    <table className={classes.dailyMinMaxTable}>
                        <thead>
                            <tr>
                                <th>
                                    <span>Hour</span>
                                </th>
                                <th>
                                    <span>Range Percentage</span>
                                </th>
                                <th>
                                    <span>Minutes In Range</span>
                                </th>
                                <th>
                                    <span>Minutes Out of Range</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>{timeGauges}</tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    );
}
