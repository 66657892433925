import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { newTimeGaugeStyle } from '../../assets/jss/DailyReportStyle';

const { defaultGaugeRadius, inRangeBorderRadius, outOfRangeBorderRadius } =
    newTimeGaugeStyle;
const useStyles = makeStyles(newTimeGaugeStyle);

export default function TimeGauge(props) {
    const classes = useStyles();

    const { inRangePercentage, outOfRangePercentage } = props;

    const getGaugeEndStyling = () => {
        let radiusStyle;

        // In range only or out of range only
        if (
            (inRangePercentage > 0 && outOfRangePercentage === 0) ||
            (inRangePercentage === 0 && outOfRangePercentage > 0)
        ) {
            radiusStyle = {
                inRange: defaultGaugeRadius,
                outOfRange: defaultGaugeRadius
            };
        } else {
            // In range and out of range
            radiusStyle = {
                inRange: inRangeBorderRadius,
                outOfRange: outOfRangeBorderRadius
            };
        }

        return radiusStyle;
    };

    return (
        <div>
            <div className={classes.gaugeContainer}>
                <div className={classes.gaugeLabels}>
                    <span className={classes.labelStartEnd}>
                        <span>In Range </span>
                        {inRangePercentage}%
                    </span>
                    <span className={classes.labelStartEnd}>
                        <span>Out of Range </span>
                        {outOfRangePercentage}%
                    </span>
                </div>
                <div className={classes.gauge}>
                    <div
                        className={classes.gaugeFillInRange}
                        style={{
                            width: inRangePercentage + '%',
                            borderRadius: getGaugeEndStyling().inRange
                        }}
                    ></div>
                    <div
                        className={classes.gaugeFillOutOfRange}
                        style={{
                            width: outOfRangePercentage + '%',
                            borderRadius: getGaugeEndStyling().outOfRange
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
}
