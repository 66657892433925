import firebase from '../../services/firebase';
import * as firebaseui from 'firebaseui';
import 'firebase/auth';

import { createSerendipityUser } from 'services/auth';
import { sendGtagEvent, GTAG_EVENTS } from 'lib/analytics';

const auth = firebase.auth();

if (process.env.REACT_APP_SERENDIPITY_ENV === 'local') {
    auth.useEmulator('http://localhost:9099');
    console.log('Using Firebase AUTH emulator for client!');
}

let firebaseUI = new firebaseui.auth.AuthUI(auth);

// The sign up path will always return true - we allow any user to login successfully during sign up / onboarding!!
// Reference this implementation: https://firebase.google.com/docs/auth/web/firebaseui
// For iOS login:  https://github.com/firebase/firebase-js-sdk/issues/6443#issuecomment-1187798276
const uiConfig = {
    signInOptions: [
        {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: true
        },
        {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID
        }
    ],
    callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            const { isNewUser } = authResult.additionalUserInfo;
            if (isNewUser) {
                sendGtagEvent(GTAG_EVENTS.CREATE_ACCOUNT);
                try {
                    createSerendipityUser(authResult, redirectUrl).then(
                        (createResult) => {
                            sendGtagEvent(GTAG_EVENTS.ACCOUNT_CREATED);
                            window.location.href =
                                createResult.serendipityRedirectUrl;
                        }
                    );
                } catch (e) {
                    return false;
                }
            } else {
                sendGtagEvent(GTAG_EVENTS.LOGIN);
                return true;
            }
        },
        uiShown: function () {
            // The widget is rendered.
            // Hide the loader.
            let spinner = document.getElementById('sign-up-spinner');

            if (spinner !== null) {
                document.getElementById('sign-up-spinner').style.display =
                    'none';
            }
        }
    },
    signInFLow: 'popup',
    signInSuccessUrl: '/admin'
};

// Start regular Firebase login flow.
export const startFirebaseLogin = async () => {
    await firebaseUI.start('#firebaseui-auth-container', uiConfig);
};
