import {
    dangerColor,
    chartColor,
    primaryColor
} from 'assets/jss/serendipity-theme';

const serverBase = {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px'
};

const stopped = {
    ...serverBase,
    backgroundColor: dangerColor[5]
};

const starting = {
    ...serverBase,
    backgroundColor: primaryColor[0]
};

const running = {
    ...serverBase,
    backgroundColor: chartColor.chartGreen
};

const requested = {
    ...starting
};

const ServerStatusStyle = {
    stopped,
    starting,
    running,
    requested,
    statusContainer: {
        paddingTop: '10px',
        display: 'flex',
        alignItems: 'center'
    },
    statusContainerLabel: {
        paddingTop: '5px'
    },
    statusLabel: {
        paddingLeft: '5px'
    },
    serverLink: {
        lineHeight: '10px',
        paddingLeft: '5px'
    }
};

export default ServerStatusStyle;
