/*eslint-disable*/
import React, { useContext } from 'react';

import { AuthContext } from '../../lib/Auth';

export default function LogoutPage() {
    const { logout } = useContext(AuthContext);
    React.useEffect(() => {
        logout();
    });

    return <div></div>;
}
