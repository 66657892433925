/*eslint-disable*/ import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
// core components
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/SerendipityFooter.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';

import landingPageStyle from 'assets/jss/material-kit-pro-react/views/landingPageStyle.js';

// Sections for this page
import SectionProduct from './Sections/SectionProduct.js';
import SectionWork from './Sections/SectionWork.js';
import SectionSerendipityPricing from './Sections/SectionSerendipityPricing.js';
import SectionPricing from './Sections/SectionPricing';
// import SectionPricing from '../PricingPage/Sections/SectionPricing';
// Copywrite text
import * as landingCopy from 'assets/copy/LandingPageCopy.js';

const useStyles = makeStyles(landingPageStyle);

export default function LandingPage({ ...rest }) {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };
    const smoothScroll = (target) => {
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
    };
    const scrollGo = (element, to, duration) => {
        var start = element.scrollTop,
            change =
                to - start + document.getElementById('main-panel').offsetTop,
            currentTime = 0,
            increment = 20;

        var animateScroll = function () {
            currentTime += increment;
            var val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };
    const classes = useStyles();
    return (
        <div>
            <Header
                color="transparent"
                brand="Serendipity Bio"
                links={<HeaderLinks dropdownHoverColor="info" />}
                fixed
                changeColorOnScroll={{
                    height: 25,
                    color: 'dark'
                }}
                {...rest}
            />
            <Parallax
                image={'assets/img/bg8.jpg'}
                filter="dark"
                id="parallax-main"
            >
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={8}>
                            <h1 className={classes.title}>
                                {landingCopy.hero}
                            </h1>
                            <h4>{landingCopy.heroSub}</h4>
                            <br />
                            <Button
                                color="danger"
                                size="lg"
                                href="/pricing"
                                onClick={(e) => {
                                    window.location.href = '/pricing';
                                }}
                            >
                                Sign Up
                            </Button>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div
                className={classNames(classes.main, classes.mainRaised)}
                id="main-panel"
            >
                <div className={classes.container}>
                    <SectionProduct id="product" />
                    <SectionSerendipityPricing id="pricing" />
                    <SectionWork id="work" />
                </div>
            </div>
            <Footer darkFont />
        </div>
    );
}
