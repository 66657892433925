import {
    successColor,
    grayColor,
    chartColor
} from 'assets/jss/serendipity-theme';

const BgCardIcon = {
    height: '55px',
    width: '127px',
    backgroundColor: successColor[3],
    borderRadius: '12px',

    display: 'inline-block',
    marginLeft: '10px',
    marginRight: '10px',

    textAlign: 'center',
    padding: '12px',
    color: grayColor[0]
};

const BgCardReadingTrend = {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    textAlign: 'center',
    height: '45px',
    width: '127px',
    backgroundColor: successColor[0],
    borderRadius: '10px',
    display: 'inline-block',
    marginLeft: '10px',
    marginRight: '10px',
    padding: '12px',
    color: successColor[7]
};

const bgCardStyle = {
    BgCardIcon: {
        ...BgCardIcon
    },
    BgCardReadingTrend: {
        ...BgCardReadingTrend
    },
    BGCardIconContainer: {
        textAlign: 'center'
    },
    BGCardCurrentReading: {
        padding: '12px',
        display: 'block',
        fontFmily: 'Manrope',
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: '600',
        textAlign: 'center',
        marginTop: '10px',
        marginLeft: '10px',
        marginRight: '10px'
    },
    BGCardUnitsLabel: {
        padding: '12px',
        display: 'block',
        fontFamily: 'Manrope',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'center',
        marginLeft: '10px',
        marginRight: '10px'
    },
    BgTrendIconNominal: {
        ...BgCardIcon
    },
    BgTrendIconHigh: {
        ...BgCardIcon,
        backgroundColor: chartColor.chartRed
    },

    BgTrendHigh: {
        ...BgCardReadingTrend,
        backgroundColor: chartColor.chartRed,
        color: grayColor[0]
    },
    BgTrendNominal: {
        ...BgCardReadingTrend
    },
    BGCardVerticalContainer: {
        marginLeft: '10px',
        height: '100%',
        width: '15px'
    },
    BGCardVerticalBar: {
        transform: ({ value }) => {
            return `translateY(${value}%) !important`;
        }
    }
};

export default bgCardStyle;
