import {
    mrAuto,
    mlAuto,
    cardTitle,
    whiteColor,
    infoColor,
    hexToRgb,
    title
} from 'assets/jss/material-kit-pro-react.js';

const pricingStyle = {
    mrAuto,
    mlAuto,
    cardTitle,
    title,
    cardTitleWhite: {
        ...cardTitle,
        color: whiteColor + ' !important',
        '& small': {
            color: 'rgba(' + hexToRgb(whiteColor) + ',0.8)!important'
        }
    },
    textCenter: {
        textAlign: 'center'
    },
    pricingSection: {
        padding: '80px 0px'
    },
    textInfo: {
        color: infoColor[0] + ' !important'
    },
    infoAreaLeft: {
        marginLeft: 'auto',
        paddingTop: 20
    },
    infoAreaRight: {
        marginRight: 'auto',
        padding: 0
    },
    submitSpinner: {
        color: whiteColor,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: 51,
        marginLeft: -18
    },
    justifyContentCenter: {
        WebkitBoxPack: 'center !important',
        MsFlexPack: 'center !important',
        justifyContent: 'center !important'
    }
};

export default pricingStyle;
