/*eslint-disable*/
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Footer from 'components/Footer/SerendipityFooter.js';
import Parallax from 'components/Parallax/Parallax.js';
import contactUsStyle from 'assets/jss/material-kit-pro-react/views/contactUsStyle.js';

import SweetAlert from 'react-bootstrap-sweetalert';
import isEmail from 'validator/lib/isEmail';
import normalizeEmail from 'validator/lib/normalizeEmail';

const useStyles = makeStyles(contactUsStyle);

export default function ContactUsPage() {
    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);
    const [contactEmail, setContactEmail] = React.useState('');
    const [contactEmailState, setContactEmailState] = React.useState('');
    const [alert, setAlert] = React.useState(null);

    // function that returns true if value is email, false otherwise
    const verifyEmail = (value) => {
        if (isEmail(value)) {
            value = normalizeEmail(value);
            return true;
        }
        return false;
    };

    const hideAlert = () => {
        setAlert(null);
    };

    const contactError = () => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: 'block' }}
                title="An error occurred."
                onCancel={() => hideAlert()}
                onConfirm={() => hideAlert()}
                confirmBtnCssClass={
                    classes.button + ' ' + classes.buttonSuccess
                }
                confirmBtnText="Try Again"
            >
                An error occurred while reaching out.
                <h5>Please check your email address and try again.</h5>
            </SweetAlert>
        );
    };

    const contactClick = async () => {
        setLoading(true);
        if (contactEmailState === 'error' || contactEmail.length === 0) {
            setLoading(false);
            setContactEmailState('error');
        } else {
            const url =
                'https://bfqt3z6hj4.execute-api.us-west-2.amazonaws.com/dev/cs/email/send';

            const contactPayload = {
                name: contactEmail,
                email: contactEmail,
                content: 'Requested a get in touch request.'
            };

            var req = new XMLHttpRequest();
            req.open('POST', url, true);
            req.addEventListener('load', function () {
                if (req.status < 400) {
                    setLoading(false);
                    setContactEmail('Thanks for reaching out!');
                } else {
                    setLoading(false);
                    contactError();
                }
            });
            req.addEventListener('error', function () {
                setLoading(false);
                contactError();
            });
            req.send(JSON.stringify(contactPayload));
        }
    };
    return (
        <div>
            <Header
                brand="Serendipity Bio"
                links={<HeaderLinks dropdownHoverColor="info" />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 25,
                    color: 'dark'
                }}
            />
            <Parallax
                image={require('assets/img/examples/city.jpg')}
                filter="dark"
                small
            >
                <div className={classes.container}>
                    <GridContainer justifyContent="center">
                        <GridItem
                            md={8}
                            sm={8}
                            className={classNames(
                                classes.mlAuto,
                                classes.mrAuto,
                                classes.textCenter
                            )}
                        >
                            <h1 className={classes.title}>Contact Us</h1>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>

            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <div className={classes.contactSection}>
                        <GridContainer>
                            <GridItem cs={12} sm={8} md={10}>
                                {alert}
                            </GridItem>
                            <GridItem
                                xs={12}
                                sm={8}
                                md={8}
                                className={
                                    classes.mlAuto +
                                    ' ' +
                                    classes.mrAuto +
                                    ' ' +
                                    classes.textCenter
                                }
                            >
                                <h2 className={classes.messageTitle}>
                                    Get in touch
                                </h2>
                                <h4 className={classes.description}>
                                    Reach out to us for any questions or
                                    comments! We'd love to hear from you, <br />{' '}
                                    even if it's to say hi!
                                </h4>
                            </GridItem>
                        </GridContainer>
                    </div>

                    <div className={classes.contactContent}>
                        <div className={classes.container}>
                            <GridContainer justifyContent="center">
                                <GridItem md={6} sm={6}>
                                    <form
                                        method="post"
                                        id="embedded-contact-form"
                                        name="embedded-contact-form"
                                        target="_blank"
                                        noValidate
                                    >
                                        <CustomInput
                                            error={
                                                contactEmailState === 'error'
                                            }
                                            labelText="Your Email Address *"
                                            id="contactUsEmail"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: (event) => {
                                                    if (
                                                        verifyEmail(
                                                            event.target.value
                                                        )
                                                    ) {
                                                        setContactEmailState(
                                                            'success'
                                                        );
                                                    } else {
                                                        setContactEmailState(
                                                            'error'
                                                        );
                                                    }
                                                    setContactEmail(
                                                        event.target.value
                                                    );
                                                },
                                                value: contactEmail,
                                                type: 'email'
                                            }}
                                        />
                                    </form>
                                </GridItem>
                                <GridItem
                                    sm={12}
                                    md={8}
                                    className={
                                        classes.mrAuto +
                                        ' ' +
                                        classes.mlAuto +
                                        ' ' +
                                        classes.textCenter
                                    }
                                >
                                    <Button
                                        color="danger"
                                        size="lg"
                                        disabled={loading}
                                        onClick={() => {
                                            contactClick();
                                        }}
                                    >
                                        Contact us
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            size={35}
                                            thickness={6}
                                            className={classes.submitSpinner}
                                        />
                                    )}
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
            </div>
            <Footer darkFont />
        </div>
    );
}
