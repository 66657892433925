import React from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import { Dashboard, GroupAdd, Storage } from '@material-ui/icons';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import { monthlyTierPricing } from 'assets/copy/LandingPageCopy';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import pricingStyle from 'assets/jss/material-kit-pro-react/views/pricingSections/pricingStyle.js';

import { handlePaymentSubmission } from 'lib/PricingHandler';

const useStyles = makeStyles(pricingStyle);

export default function SectionPricing({ ...rest }) {
    const classes = useStyles();

    const [paymentLoading, setPaymentLoading] = React.useState(false);

    const handlePaymentClick = async (subscriptionType) => {
        setPaymentLoading(true);
        await handlePaymentSubmission(subscriptionType);
    };

    return (
        <div className={classes.pricingSection} {...rest}>
            <GridContainer>
                <GridItem sm={12} md={6}>
                    <GridContainer>
                        <GridItem>
                            <h2 className={classes.title}>
                                Zero risk, monthly membership
                            </h2>
                            <h5 className={classes.description}>
                                Cancel any time, no questions asked.
                            </h5>
                        </GridItem>
                        <GridItem>
                            <GridContainer>
                                <GridItem>
                                    <GridContainer>
                                        <GridItem md={6}>
                                            <InfoArea
                                                className={classes.infoAreaLeft}
                                                title="Advanced reporting."
                                                description="Make sense of your data in one place."
                                                icon={Dashboard}
                                                iconColor="info"
                                            />
                                        </GridItem>
                                        <GridItem md={6}>
                                            <InfoArea
                                                className={classes.infoAreaLeft}
                                                title="High reliability."
                                                description="Built so you worry less."
                                                icon={Storage}
                                                iconColor="success"
                                            />
                                        </GridItem>
                                        <GridItem md={6}>
                                            <InfoArea
                                                className={
                                                    classes.infoAreaRight
                                                }
                                                title="Easy to share."
                                                description="Share with anyone."
                                                icon={GroupAdd}
                                                iconColor="warning"
                                            />
                                        </GridItem>
                                        <GridItem md={6}>
                                            <InfoArea
                                                className={
                                                    classes.infoAreaRight
                                                }
                                                title="24 x 7 x 365 Support."
                                                description="We're here to help you at anytime."
                                                icon={QuestionAnswer}
                                                iconColor="rose"
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={5} className={classes.mrAuto}>
                    <Card pricing color="success">
                        <CardBody pricing>
                            <h1 className={classes.cardTitleWhite}>
                                <small>$</small>
                                {monthlyTierPricing} <small>/mo</small>
                            </h1>
                            <ul>
                                <li>We deploy Nightscout for you</li>
                                <li>Unlimited reporting</li>
                                <li>Unlimited sharing</li>
                                <li>Priority access to new features</li>
                                <li>24x7x365 support</li>
                            </ul>
                        </CardBody>
                        <CardFooter
                            pricing
                            className={classes.justifyContentCenter}
                        >
                            <Button
                                color="white"
                                round
                                disabled={paymentLoading}
                                onClick={() => {
                                    handlePaymentClick({
                                        monthly: true
                                    });
                                }}
                            >
                                Get Started
                            </Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
