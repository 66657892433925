import { container, coloredShadow } from 'assets/jss/material-kit-pro-react.js';

const carouselStyle = {
    section: {
        padding: '70px 0'
    },
    container,
    coloredShadow,
    marginAuto: {
        marginLeft: 'auto !important',
        marginRight: 'auto !important'
    }
};

export default carouselStyle;
