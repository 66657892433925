import { getIdToken } from 'services/auth';
import { sendGtagEvent, GTAG_EVENTS } from './analytics';

export const PRODUCT_TYPE = {
    DEXCOM_LIVE_MONTHLY: 'DEXCOM_LIVE_MONTHLY',
    NIGHTSCOUT_MONTHLY: 'NIGHTSCOUT_MONTHLY'
};

export const handlePaymentSubmission = async (subscriptionType) => {
    const idToken = await getIdToken();

    // No user has signed in, redirect the user to create an account.
    if (!idToken) {
        sendGtagEvent(GTAG_EVENTS.SIGN_UP);

        switch (subscriptionType.product) {
            case PRODUCT_TYPE.DEXCOM_LIVE_MONTHLY:
                window.location.href = '/signup?plan=dexcom_live_monthly';
                break;
            case PRODUCT_TYPE.NIGHTSCOUT_MONTHLY:
                window.location.href = '/signup?plan=nightscout_monthly';
                break;
            default:
                window.location.href = '/signup?plan=nightscout_monthly';
                break;
        }
    } else {
        window.location.href = 'admin';
    }
};
