import {
    chartColor,
    grayColor,
    primaryColor
} from 'assets/jss/serendipity-theme';

import dashboardStyle from '../../components/Serendipity/GlucoseGraphStyle';

const { legendDotGreen, legendDotRed, tableLegendText } = dashboardStyle;
const dailyReportStyleBase = {
    cardHeader: {
        paddingBottom: '0'
    },
    cardTitle: {
        paddingTop: '10px',
        paddingLeft: '5px'
    },
    cardTitleText: {
        textAlign: 'left',
        fontSize: '20px',
        fontWeight: '600'
    },
    cardBody: {
        height: '430px',
        display: 'flex'
    },
    dailyMinMaxTableContainer: {
        width: '100%',
        paddingLeft: '5px'
    },
    dailyMinMaxTable: {
        width: '100%',
        '& th': {
            ...tableLegendText
        }
    },

    legendDotGreen,
    legendDotRed,
    chartColor
};

const timeGaugeStyles = {
    ...dailyReportStyleBase,

    cardBody: {
        height: '570px'
    },

    timeLabel: {
        paddingTop: '20px'
    },

    timeGaugeContainer: {
        height: '750px',
        display: 'flex',
        overflow: 'hidden',
        marginLeft: '10px',
        justifyContent: 'center',
        alignItems: 'center'
    },

    timeGauge: {
        display: 'flex',
        width: '600px' /* Width can be adjusted based on requirement */,
        height: '30px'
    },

    segment: {
        flexGrow: 1,
        borderRight: '1px solid #fff' /* White lines between segments */,
        margin: '2px',
        borderRadius: '2px'
    },

    inRange: {
        backgroundColor: chartColor.chartGreen
    },

    outOfRange: {
        backgroundColor: chartColor.chartRed
    },

    empty: {
        backgroundColor: grayColor[3]
        // '#ccc'
        /* Color for empty segments, e.g., light grey */
    },
    gaugeColumnHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px 0',
        width: '600px'
    },

    gaugeEntry: {
        paddingRight: '30px'
    }
};

const gaugeFill = {
    height: '100%',
    position: 'aboslute',
    left: 0
};

const newTimeGaugeStyle = {
    gaugeContainer: {
        width: '100%'
    },
    gauge: {
        position: 'relative',
        height: '20px',
        backgroundColor: grayColor[3],
        borderRadius: '20px',
        width: '100%'
    },
    gaugeFillInRange: {
        ...gaugeFill,
        borderRadius: '15px',
        backgroundColor: primaryColor[0],
        float: 'left',
        overflow: 'hidden'
    },
    gaugeFillOutOfRange: {
        ...gaugeFill,
        backgroundColor: chartColor.chartRed,
        float: 'right',
        overflow: 'hidden',
        display: 'block'
    },
    gaugeLabels: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '5px'
    },
    defaultGaugeRadius: '15px',
    inRangeBorderRadius: '15px 0px 0px 15px',
    outOfRangeBorderRadius: '0px 15px 15px 0px'
};

const dailyEntriesStyle = {
    lowThreshold: chartColor.chartRed,
    highThreshold: chartColor.chartRed,
    inRange: chartColor.chartGreen
};
export {
    dailyReportStyleBase,
    timeGaugeStyles,
    dailyEntriesStyle,
    newTimeGaugeStyle
};
