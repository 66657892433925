/*eslint-disable*/
import React, { useEffect } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import ContactSupport from '@material-ui/icons/ContactSupport';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const useStyles = makeStyles(styles);

export default function SupportPage() {
    const classes = useStyles();

    const supportUrl =
        process.env.SERENDIPITY_ENV == 'local'
            ? 'https://support.test.serendipitybio.com'
            : 'https://support.serendipitybio.com';

    useEffect(() => {
        const timeout = setTimeout(() => {
            // 👇️ redirects to an external URL
            window.location.replace(supportUrl);
        }, 1500);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
                <Card>
                    <CardHeader color="success" text>
                        <CardIcon color="success">
                            <ContactSupport />
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <div className={classes.cardContentLeft}>
                            <p>Redirecting you to our Support page...</p>
                        </div>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}
